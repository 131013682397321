import React, { useState, useEffect } from "react";

import { createInquilino } from "../../../../api/inquilino";

import { obtenerListaInmuebles } from "../../../../api/inmueble";
import { getAccessTokenApi } from "../../../../api/auth";
import {
  Avatar,
  Form,
  Input,
  Select,
  Button,
  Row,
  Col,
  notification,
  DatePicker,
  Radio,
  Space,
  Divider,
} from "antd";

import {
  BorderBottomOutlined,
  BorderTopOutlined,
  RadiusBottomleftOutlined,
  RadiusBottomrightOutlined,
  RadiusUpleftOutlined,
  RadiusUprightOutlined,
} from "@ant-design/icons";
import "./AddInquilinoForm.scss";
import moment from "moment";
import Boton_peticion from "../../boton_peticion/boton_peticion";

export default function EditInquilinoForm(props) {
  const { setIsVisibleModal, setReloadUsers } = props;
  const [userData, setUserData] = useState({});
  const openNotification = () => {
    //console.log("Abierto");
    notification["success"]({
      message: "Usuario creado",
      description: "El usuario ha sido creado correctamente.",
      onClick: () => {
        //console.log("Notification Clicked!");
      },
    });
  };
  const [isLoading, setisLoading] = useState(false);
  const addUser = (event) => {
    //console.log("creando");

    if (
      !userData.name ||
      !userData.inmueble ||
      !userData.fecha_entrada ||
      !userData.telefono ||
      !userData.fecha_salida ||
      !userData.tipo_inquilino
    ) {
      //console.log("creando1");
      //console.log(userData);
      notification["error"]({
        message: "Todos los campos son obligatorios.",
      });
    } else if (userData.password !== userData.repeatPassword) {
      //console.log("creando2");
      notification["error"]({
        message: "Las contraseñas tienen que ser iguale.",
      });
    } else {
      //console.log("creando3");
      const accesToken = getAccessTokenApi();
      //console.log(userData);
      if (!userData.idioma_mensaje) {
        userData.idioma_mensaje = "ES";
      }
      setisLoading(true);
      createInquilino(accesToken, userData)
        .then((response) => {
          setisLoading(false);
          //console.log("Responde: ", response);
          setUserData({});
          //console.log("userdata: ", userData);

          setIsVisibleModal(false);
          setReloadUsers(true);
          openNotification();
        })
        .catch((err) => {
          setisLoading(false);
          notification["error"]({
            message: err,
          });
        });
    }
  };

  return (
    <div className="add-user-form">
      <AddForm
        userData={userData}
        setUserData={setUserData}
        addUser={addUser}
        isLoading={isLoading}
      />
    </div>
  );
}

function AddForm(props) {
  const { userData, setUserData, addUser, isLoading } = props;
  const { Option } = Select;
  const [num_tours_v, set_num_tours_v] = useState([]);
  const [pisos, set_pisos] = useState({});
  //console.log("OOLLLOOOPPPSPAPAOSPAOS");
  useEffect(() => {
    const accesToken = getAccessTokenApi();
    //console.log("accesToken", accesToken);

    obtenerListaInmuebles(accesToken).then((response) => {
      //console.log(response.Pisos[0].id);
      //console.log("obtenerListaInmuebles", response.Pisos2);
      var num_tours_v2 = [];
      //console.log("1");
      //console.log("LONGITUD", response.Pisos.length);
      for (let i = 0; i < response.Pisos.length; i++) {
        num_tours_v2.push(
          <Option value={response.Pisos[i].id}>
            {response.Pisos[i].nombre}
          </Option>
        );
      }
      setUserData({ ...userData, tipo_inquilino: "Inquilino" });
      set_pisos(response.Pisos2);
      set_num_tours_v(num_tours_v2);
    });
  }, []);
  const { RangePicker } = DatePicker;
  const [value, setValue] = useState(1);

  return (
    <Form className="form-add" onFinish={addUser}>
      <Divider>Fecha de entrada</Divider>
      <Row gutter={24}>
        <Form.Item label="Date Range">
          <RangePicker
            renderExtraFooter={() => "extra footer"}
            value={[
              userData.fecha_entrada ? moment(userData.fecha_entrada) : null,
              userData.fecha_salida ? moment(userData.fecha_salida) : null,
            ]}
            onChange={(dates) => {
              //console.log("onChange");
              //console.log(dates[0]);
              //console.log(dates[1]);
              setUserData({
                ...userData,
                fecha_entrada: dates[0].format("YYYY-MM-DD"),
                fecha_salida: dates[1].format("YYYY-MM-DD"),
              });
            }}
          />
        </Form.Item>
        <Divider>Sobre el inquilino</Divider>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="user" />}
              placeholder="Nombre y apellidos Inquilino"
              value={userData.name}
              onChange={(e) =>
                setUserData({ ...userData, name: e.target.value })
              }
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="user" />}
              placeholder="Email"
              value={userData.email}
              onChange={(e) =>
                setUserData({ ...userData, email: e.target.value })
              }
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Telefono"
              value={userData.telefono}
              onChange={(e) =>
                setUserData({ ...userData, telefono: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={24}>
        <Col span={24}>
          <Form.Item>
            <Select
              placeholder="Seleciona un inmueble"
              onChange={(e) => {
                //console.log("Comisiones a mirar", userData)
                //console.log(pisos)
                setUserData({
                  ...userData,
                  inmueble: e,
                  pago_limpieza:
                    Object.keys(pisos).length === 0
                      ? ""
                      : e in pisos
                      ? pisos[e].coste_limpieza.replace(",", ".") ?? ""
                      : "",
                  
                });
              }}
              value={userData.inmueble}
            >
              {num_tours_v}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      {/* <Row gutter={24}>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="lock" />}
              type="password"
              placeholder="Contraseña"
              value={userData.password}
              onChange={(e) =>
                setUserData({ ...userData, password: e.target.value })
              }
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="lock" />}
              type="password"
              placeholder="Repetir contraseña"
              value={userData.repeatPassword}
              onChange={(e) =>
                setUserData({ ...userData, repeatPassword: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row> */}
      <Divider>Reserva</Divider>
      <Row gutter={24}>
        <Col span={12}>Numero de personas</Col>
        <Col span={12}>
          <Form.Item>
            <Input
              placeholder="Numero de personas"
              onChange={(e) =>
                setUserData({ ...userData, numero_personas: e.target.value })
              }
              value={userData.numero_personas}
            />
          </Form.Item>
        </Col>
      </Row>
      <Divider>Pagos</Divider>
      <Row gutter={24}>
        <Col span={24}>
          <Form.Item>
            <Select
              placeholder="Plataforma"
              onChange={(e) =>
                setUserData({
                  ...userData,
                  plataforma: e,
                  comision: e === "Referido" ? "0" : "",
                  
                })
              }
              value={userData.plataforma}
            >
              <Option value="Airbnb">Airbnb</Option>
              <Option value="Booking">Booking</Option>
              <Option value="Vrbo">Vrbo</Option>
              <Option value="Web">Web</Option>
              <Option value="Referido">Referido</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Pagado
        </Col>
        <Col span={12}>
        <Form.Item>
          <Input
            //prefix={<Icon type="mail" />}
            placeholder="Pago"
            value={userData.pago}
            onChange={(e) => {
              ////console.log(e.target.value);
              const inputValue = e.target.value.replace(",", ".");
              let comision =
                userData.plataforma === "Airbnb"
                  ? Number((pisos[userData.inmueble]?.comision_airbnb ?? 0) * inputValue)/100
                  : userData.plataforma === "Booking"  || userData.plataforma === "Booking.com"
                  ? Number((pisos[userData.inmueble]?.comision_booking ?? 0) * inputValue)/100
                  : Number((15) * inputValue)/100;

              comision = Math.ceil(comision * 100) / 100; // Redondear hacia arriba y dejar solo dos decimales
              setUserData({
                ...userData,
                pago: inputValue,
                comision: comision.toFixed(2)
              });
            }}
          />
        </Form.Item>

        </Col>
      </Row>

      <Row gutter={24}>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Pago Limpieza
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Pago limpieza"
              value={userData.pago_limpieza}
              onChange={
                (e) =>
                  setUserData({
                    ...userData,
                    pago_limpieza: e.target.value.replace(",", "."),
                  })
                ////console.log(e.target.value)
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Comision (%)
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              disabled
              placeholder="% Comision"
              value={
                Number((userData.comision / userData.pago) * 100).toFixed(2) + "%"
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Comision (euros)
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="comision"
              value={userData.comision}
              onChange={(e) =>
                setUserData({ ...userData, comision: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Divider>Factura</Divider>
      <Row gutter={24}>
        <Col span={24}>
          <Form.Item>
            <Radio.Group
              onChange={(e) => {
                //console.log("Dato:", e.target.value);
                setUserData({
                  ...userData,
                  tax_percentage: e.target.value,
                  taxes_for_invoicing:
                    e.target.value === "21"
                      ? Number((e.target.value / 100) * userData.pago).toFixed(
                          2
                        )
                      : e.target.value === "10"
                      ? Number((e.target.value / 100) * userData.pago).toFixed(
                          2
                        )
                      : e.target.value === "0"
                      ? 0
                      : e.target.value,
                });
              }}
              defaultValue={"0"}
            >
              <Space direction="horizontal">
                <Radio value={"0"}>Sin Factura</Radio>
                <Radio value={"21"}>Factura 21%</Radio>
                <Radio value={"10"}>Factura 10%</Radio>
                <Radio value={""}>
                  Otra
                  {value === "" ? (
                    <Input
                      value={userData.tax_percentage}
                      onChange={(e) => {
                        //console.log("Dato:", e.target.value);
                        setUserData({
                          ...userData,
                          tax_percentage: e.target.value,
                          taxes_for_invoicing: Number(
                            (e.target.value / 100) * userData.pago
                          ).toFixed(2),
                        });
                      }}
                      style={{ width: 100, marginLeft: 10 }}
                    />
                  ) : null}
                </Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      {(userData.tax_percentage ?? "0") === "0" ? (
        <></>
      ) : (
        <Row gutter={24}>
          <Col
            span={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            IVA (euros)
          </Col>

          <Col span={12}>
            <Form.Item>
              <Input
                //prefix={<Icon type="mail" />}
                placeholder="Taxes"
                value={userData.taxes_for_invoicing}
                onChange={(e) => {}}
              />
            </Form.Item>
          </Col>
        </Row>
      )}
      <Divider>Idioma para el cliente</Divider>
      <Row gutter={24}>
        <Col span={24}>
          <Form.Item>
            <Radio.Group
              onChange={(e) => {
                setUserData({ ...userData, idioma_mensaje: e.target.value });
              }}
              defaultValue={"ES"}
              value={userData.idioma_mensaje}
            >
              <Space direction="horizontal">
                <Radio value={"ES"}>Español</Radio>
                <Radio value={"EN"}>Ingles</Radio>
                <Radio value={"FR"}>Frances</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Divider>Tipo de cliente</Divider>
      <Row gutter={24}>
        <Col span={24}>
          <Form.Item>
            <Radio.Group
              onChange={(e) => {
                setUserData({ ...userData, tipo_inquilino: e.target.value });
              }}
              defaultValue={"Inquilino"}
              value={userData.tipo_inquilino}
            >
              <Space direction="horizontal">
                <Radio value={"Inquilino"}>Inquilino</Radio>
                <Radio value={"Propietario"}>Propietario</Radio>
                <Radio value={"Limpiador"}>Limpiador</Radio>
                <Radio value={""}>
                  Otro
                  {userData.tipo_inquilino === "" ? (
                    <Input
                      value={userData.tipo_inquilino}
                      onChange={(e) =>
                        setUserData({
                          ...userData,
                          tipo_inquilino: e.target.value,
                        })
                      }
                      style={{ width: 100, marginLeft: 10 }}
                    />
                  ) : null}
                </Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>

      <Divider></Divider>

      <Row gutter={24}>
        <Col span={24}>
                        
          </Col>
          </Row>
      <Divider></Divider>
      <Form.Item>
        <Boton_peticion texto={"Crear Inquilino"} isLoading={isLoading} />
      </Form.Item>
    </Form>
  );
}

import React, { useEffect, useState } from "react";
import { Table, Typography, Button } from "antd";

const { Title } = Typography;

// Componente para el resumen de impuestos
const TaxSummaryTable = ({ facturas, onViewFacturas }) => {
  const [taxSummary, setTaxSummary] = useState([]);

  // Función para calcular el resumen de impuestos
  const calculateTaxSummary = (facturas) => {
    const taxSummary = {};

    facturas.forEach((factura) => {
      factura.conceptos_facturar.forEach((concepto) => {
        const taxPercentage = concepto.tax_percentage;

        if (!taxSummary[taxPercentage]) {
          taxSummary[taxPercentage] = {
            taxPercentage,
            totalTaxNumber: 0,
            totalPrecioUd: 0,
            facturas: [],
          };
        }

        taxSummary[taxPercentage].totalTaxNumber += parseFloat(concepto.tax_number || 0);
        taxSummary[taxPercentage].totalPrecioUd += parseFloat(concepto.precio_ud || 0);
        taxSummary[taxPercentage].facturas.push(factura);
      });
    });

    return Object.values(taxSummary);
  };

  useEffect(() => {
    if (facturas && facturas.length > 0) {
      const summary = calculateTaxSummary(facturas);
      setTaxSummary(summary);
    }
  }, [facturas]);

  // Formateador para valores monetarios
  const currencyFormatter = new Intl.NumberFormat("es-ES", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  // Columnas para la tabla
  const taxColumns = [
    {
      title: "Tipo de Impuesto (%)",
      dataIndex: "taxPercentage",
      key: "taxPercentage",
    },
    {
      title: "Total Impuesto (€)",
      dataIndex: "totalTaxNumber",
      key: "totalTaxNumber",
      render: (value) => {
        const formattedValue = currencyFormatter.format(parseFloat(value));
        console.log("Formatted Total Impuesto:", formattedValue); // Para depuración
        return formattedValue;
      },
    },
    {
      title: "Total Precio Unitario (€)",
      dataIndex: "totalPrecioUd",
      key: "totalPrecioUd",
      render: (value) => {
        const formattedValue = currencyFormatter.format(parseFloat(value));
        console.log("Formatted Total Precio Unitario:", formattedValue); // Para depuración
        return formattedValue;
      },
    },
    {
      title: "Acciones",
      key: "actions",
      render: (_, record) => (
        <Button
          type="primary"
          onClick={() => onViewFacturas(record.facturas)}
        >
          Ver Facturas
        </Button>
      ),
    },
  ];

  return (
    <div style={{ marginTop: "20px" }}>
      <Title level={4}>Resumen de Impuestos</Title>
      <Table
        dataSource={taxSummary}
        columns={taxColumns}
        rowKey="taxPercentage"
        pagination={false}
      />
    </div>
  );
};

export default TaxSummaryTable;

import React, { useState, useEffect } from "react";
import { getAccessTokenApi } from "../../../../api/auth";
import { eliminarFacturaAsociada, obtenerFacturasPlataformaFechas, verFacturas, getSeries } from "../../../../api/series";
import { Button, List, DatePicker, Card, Select, Modal, Typography, Row, Col, Divider, Collapse } from "antd";
import jwt_decode from "jwt-decode";
import moment from "moment";
import { getInmueblesApi } from "../../../../api/inmueble";
import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import FacturasProductos from "../../../../components/FacturasProductos/FacturasProductos";
import TaxSummaryTable from "./TaxSummaryTable";

const { RangePicker } = DatePicker;
const { Text, Title } = Typography;

export default function FacturasPlataformaFechas() {
  const [dateRange, setDateRange] = useState([null, null]);
  const [selectedInmueble, setSelectedInmueble] = useState(null);
  const [inmuebles, setInmuebles] = useState([]);
  const [facturas, setFacturas] = useState([]);
  const [seriesMap, setSeriesMap] = useState({});
  const [series, setSeries] = useState([]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentPdfUrl, setCurrentPdfUrl] = useState("");
  const [selectedEmisor, setSelectedEmisor] = useState(null); // Estado para el filtro del emisor
  const accessToken = getAccessTokenApi();
  const [selectedSerie, setSelectedSerie] = useState([]);

  const [facturasFiltradas, setFacturasFiltradas] = useState([]);
  const [isModalFacturasVisible, setIsModalFacturasVisible] = useState(false);



  // Función para mostrar las facturas filtradas
  const handleViewFacturas = (facturas) => {
    setFacturasFiltradas(facturas);
    setIsModalFacturasVisible(true);
  };





  useEffect(() => {
    async function fetchInmuebles() {
      const response = await getInmueblesApi(accessToken, 1, true);
      if (response.Pisos) setInmuebles(response.Pisos);
    }
    async function fetchSeries2() {
      const response = await getSeries(accessToken);
      if (response.series) setSeries(response.series);
    }

    async function fetchSeries() {
      const response = await getSeries(accessToken);
      if (response.series) {
        const seriesMapping = response.series.reduce((acc, serie) => {
          acc[serie._id] = serie.nombre_serie;
          return acc;
        }, {});
        setSeriesMap(seriesMapping);
      }
    }
    fetchSeries2();
    fetchInmuebles();
    fetchSeries();
  }, [accessToken]);

  const handleDateChange = (dates) => setDateRange(dates);

  const handleInmuebleChange = (value) => setSelectedInmueble(value);

  const handleSearch = async () => {
    const [startDate, endDate] = dateRange || [];
    const formattedStartDate = startDate ? startDate.format("YYYY-MM-DD") : null;
    const formattedEndDate = endDate ? endDate.format("YYYY-MM-DD") : null;

    const response = await obtenerFacturasPlataformaFechas(
      accessToken,
      selectedInmueble,
      formattedStartDate,
      formattedEndDate
    );

    if (response.error) {
      setFacturas([]);
    } 
    else if (response && response.archivos) 
    {
      setFacturas(response.archivos);
    }
  };

  const handleDeletePdf = async (pdfId) => {
    try {
      await eliminarFacturaAsociada(accessToken, selectedInmueble, pdfId);
      handleSearch();
    } catch (error) {
      console.error("Error al eliminar archivo PDF:", error);
    }
  };

  const handleViewPdf = async (pdfId) => {
    try {
      const pdfUrl = await verFacturas(accessToken, selectedInmueble, pdfId);
      setCurrentPdfUrl(pdfUrl);
      setIsModalVisible(true);
    } catch (error) {
      console.error("Error al visualizar el archivo PDF:", error);
    }
  };
  const handleViewDetails = (factura) => {
    Modal.info({
      title: `Detalles de la Factura Nº ${factura.numero_factura}`,
      content: (
        <div>
          <p><strong>Emisor:</strong> {factura.data_emisor?.nombre}</p>
          <p><strong>Receptor:</strong> {factura.data_receptor?.nombre}</p>
          <p><strong>Total:</strong> {factura.conceptos_facturar.reduce((acc, item) => acc + parseFloat(item.total), 0).toFixed(2)} €</p>
          <p><strong>Conceptos:</strong></p>
          <ul>
            {factura.conceptos_facturar.map((concepto, index) => (
              <li key={index}>
                {concepto.descripcion_item} - {concepto.total} €
              </li>
            ))}
          </ul>
        </div>
      ),
      onOk() {},
    });
  };
  


  // const filteredFacturas = facturas.filter((factura) => {
  //   const emisorMatch = selectedEmisor?.length
  //     ? selectedEmisor.includes(factura.data_emisor?.nombre)
  //     : true;
  
  //   const inmuebleMatch = selectedInmueble?.length
  //     ? selectedInmueble.includes(factura.inmueble_id) // Ajusta según tu modelo de datos
  //     : true;

  //    const existe_serie = seriesMap[factura.serie_facturacion] ? true : false;
  
  //   return emisorMatch && inmuebleMatch && existe_serie;
  // });

  
  const [selectedReceptor, setSelectedReceptor] = useState(null); // Estado para el filtro de receptor

  const handleSerieChange = (value) => setSelectedSerie(value);

  const filteredFacturas2 = facturas.filter((factura) => {
    if (selectedSerie.length > 0 && !selectedSerie.includes("TODAS")) {
      return selectedSerie.includes(factura.serie_facturacion);
    }
    return true;
  });
    
const filteredFacturas = filteredFacturas2.filter((factura) => {
  const emisorMatch = selectedEmisor?.length
    ? selectedEmisor.includes(factura.data_emisor?.nombre)
    : true;

  const receptorMatch = selectedReceptor?.length
    ? selectedReceptor.includes(factura.data_receptor?.nombre)
    : true;

  const inmuebleMatch = selectedInmueble?.length
    ? selectedInmueble.includes(factura.inmueble_id)
    : true;

 
  const existe_serie = seriesMap[factura.serie_facturacion] ? true : false;

  return emisorMatch && receptorMatch && inmuebleMatch && existe_serie;
});



  
  return (
    <div>

      {facturas ? 
      <>
      <h1>Resumen de Facturas</h1>
      <TaxSummaryTable facturas={filteredFacturas} onViewFacturas={handleViewFacturas} />
      <Modal
        title="Facturas Asociadas"
        visible={isModalFacturasVisible}
        onCancel={() => setIsModalFacturasVisible(false)}
        footer={null}
      >
        <List
          itemLayout="vertical"
          dataSource={filteredFacturas}
          renderItem={(factura) => (
            <List.Item>
              <Card title={`Factura Nº: ${factura.numero_factura}`}>
                <p><strong>Emisor:</strong> {factura.data_emisor?.nombre}</p>
                <p><strong>Total:</strong> {factura.conceptos_facturar.reduce((acc, item) => acc + parseFloat(item.total), 0).toFixed(2)} €</p>
              </Card>
            </List.Item>
          )}
        />
         </Modal>

      </>:<></>
      }
      <div style={{ marginBottom: "20px" }}>
      <Select
          mode="multiple"
          placeholder="Selecciona una serie"
          style={{ minWidth: 200, marginLeft: 10 }}
          onChange={handleSerieChange}
          allowClear
        >
          <Select.Option key="TODAS" value="TODAS">- SELECCIONAR TODAS -</Select.Option>
          {series.map((serie) => (
            <Select.Option key={serie._id} value={serie._id}>{serie.nombre_serie}</Select.Option>
          ))}
        </Select>
        <Select
          placeholder="Selecciona un inmueble"
          style={{ width: 200, marginRight: 10 }}
          onChange={handleInmuebleChange}
        >
          {inmuebles.map((inmueble) => (
            <Select.Option key={inmueble._id} value={inmueble._id}>
              {inmueble.name}
            </Select.Option>
          ))}
        </Select>

        <RangePicker format="YYYY-MM-DD" onChange={handleDateChange} />
        <Button type="primary" onClick={handleSearch} style={{ marginLeft: 10 }}>
          Buscar
        </Button>


        <Select
          mode="multiple" // Permite seleccionar múltiples receptores
          placeholder="Filtrar por receptor"
          style={{ width: 200, marginLeft: 10 }}
          onChange={(values) => setSelectedReceptor(values)} // Maneja múltiples receptores
        >
          {[...new Set(facturas.map((factura) => factura.data_receptor?.nombre))]
            .filter((receptor) => receptor) // Filtrar receptores válidos
            .sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' })) // Ordenar alfabéticamente
            .map((receptor) => (
              <Select.Option key={receptor} value={receptor}>
                {receptor}
              </Select.Option>
            ))}
        </Select>

        <Select
          mode="multiple" // Habilita selección múltiple
          placeholder="Filtrar por emisor"
          style={{ width: 200, marginLeft: 10 }}
          onChange={(values) => setSelectedEmisor(values)} // Maneja múltiples emisores
        >
          {[...new Set(facturas.map((factura) => factura.data_emisor?.nombre))]
            .filter((emisor) => emisor) // Filtrar emisores válidos
            .sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' })) // Ordenar alfabéticamente, sin importar mayúsculas o minúsculas// Filtrar emisores válidos
            .map((emisor) => (
              <Select.Option key={emisor} value={emisor}>
                {emisor}
              </Select.Option>
            ))}
        </Select>

        Total de facturas en el periodo: {filteredFacturas.length}
        

      </div>

      <List
        itemLayout="vertical"
        dataSource={filteredFacturas}
        renderItem={(factura) => (
          <List.Item>
            <Card
              title={`Factura Nº: ${factura.numero_factura} | Serie: ${seriesMap[factura.serie_facturacion]  || "Serie no encontrada"} | Fecha emitida: ${factura.fecha_emision_factura} `}
              bordered
              extra={
                <div>
                  {jwt_decode(accessToken).role === "Administrador" && (
                    <Button
                      type="primary"
                      danger
                      icon={<DeleteOutlined />}
                      onClick={() => handleDeletePdf(factura._id)}
                      style={{ marginRight: 8 }}
                    >
                      Eliminar
                    </Button>
                  )}
                  <Button
                    type="primary"
                    icon={<EyeOutlined />}
                    onClick={() => handleViewPdf(factura._id)}
                  >
                    Ver
                  </Button>
                </div>
              }
            >
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Title level={5}>Datos del Emisor</Title>
                  <Text><strong>Nombre:</strong> {factura.data_emisor?.nombre}</Text><br />
                  <Text><strong>Dirección:</strong> {factura.data_emisor?.direccion_emisor}</Text><br />
                  <Text><strong>NIF:</strong> {factura.data_emisor?.numero_identificacion_fiscal}</Text>
                </Col>
                <Col span={12}>
                  <Title level={5}>Datos del Receptor</Title>
                  <Text><strong>Nombre:</strong> {factura.data_receptor.nombre}</Text><br />
                  <Text><strong>Dirección:</strong> {factura.data_receptor.direccion_emisor}</Text><br />
                  <Text><strong>NIF:</strong> {factura.data_receptor.numero_identificacion_fiscal}</Text>
                </Col>
              </Row>
              <Divider />

              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Title level={5}>Conceptos Facturados {factura.conceptos_facturar.reduce((acc, item) => acc + parseFloat(item.total), 0).toFixed(2)} € </Title>
                
                  <Collapse>
                  <Collapse.Panel>
                  {factura.conceptos_facturar.map((concepto, index) => (
                    <>
                    <Card key={index} type="inner" style={{ marginBottom: "10px" }}>
                      <Text><strong>Descripción:</strong> {concepto.descripcion_item}</Text><br />
                      <Text><strong>Cantidad:</strong> {concepto.cantidad}</Text><br />
                      <Text><strong>Precio Unitario:</strong> {concepto.precio_ud} €</Text><br />
                      <Text><strong>Impuesto (%):</strong> {concepto.tax_percentage}</Text><br />
                      <Text><strong>Impuesto (€):</strong> {concepto.tax_number} €</Text><br />
                      <Text><strong>Total:</strong> {concepto.total} €</Text>
                    </Card>
                     <Collapse>
                     
                       <FacturasProductos
                                 irnr={factura?.irnr}
                                 retencion={factura?.retencion}
                                 serie_nombre =  {seriesMap[factura.serie_facturacion]}
                                 numero_factura={factura?.numero_factura ?? ""}
                                 nombre_lomo={""                                 }
                                 proforma={false}
                                 nombre_emisor={factura?.data_emisor?.nombre ?? ""}
                                 direccionEmisor={factura?.data_emisor?.direccion_emisor ?? ""}
                                 nifEmisor={factura?.data_emisor?.numero_identificacion_fiscal ?? ""}
                                 nombreReceptor={factura?.data_receptor.nombre ?? ""}
                                 direccionReceptor={factura?.data_receptor.direccion_emisor ?? ""}
                                 nifReceptor={factura?.data_receptor.numero_identificacion_fiscal ?? ""}
                                 elementosFactura={factura?.conceptos_facturar ?? ""}
                                 fecha_creacion={factura?.fecha_emision_factura ?? ""}
                                 datos_facturacion_administrador={factura?.datosFacturacionAdministrador ?? []}
                                 datos_mes={factura?.conceptos_facturar ?? []}
                                //  inquilinos={usersActive ?? []}
                                 datos_facturacion={factura?.inmueble_facturacion ?? {}}
                       
                               />
                     
                   </Collapse>
                   </>
                  ))}
                  </Collapse.Panel>
                  </Collapse>
                 
                </Col>
              </Row>

              <Divider />
              
            </Card>
          </List.Item>
        )}
      />

      <Modal
        title="Visualización de PDF"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        width="80%"
      >
        <iframe
          src={currentPdfUrl}
          style={{ width: "100%", height: "800px" }}
          frameBorder="0"
        />
      </Modal>
    </div>
  );
}

import React, { useState } from "react";
import { Collapse, Button, message } from "antd";
import moment from "moment";
import { generarFacturas, getSeriesInmueble } from "../../../../api/series";
import { getAccessTokenApi } from "../../../../api/auth";
import Boton_factura_CC from "./Boton_factura_CC";

const { Panel } = Collapse;

const ListaFacturasModal = ({ facturas, monthQuery, yearQuery }) => {
  const [loading, setLoading] = useState(false);

  const generarFacturasPorTipo = async (tipo) => {
    setLoading(true);

    try {
      const facturasData = await Promise.all(
        facturas.flatMap(async (record) => {
          const serie_a_enviar = "SERIE_DEFECTO";

          if (tipo === "subarriendo") {
            let facturasSubarriendo = [
              {
                nombreEmisor: record.nombre_facturacion,
                nifEmisor: record.id_facturacion,
                direccionEmisor: record.direccion_facturacion,
                cantidad: "1",
                precioUd: (Number(record.Propietario_resto) / 1.21).toFixed(2),
                descripcionItem: `Factura Subarriendo Clientes Varios - ${record.nombre} - Mes ${monthQuery + 1}/${yearQuery}`,
                nombreReceptor: "CLIENTES VARIOS",
                direccionReceptor: "",
                nifReceptor: "",
                taxPercentage: "10%",
                taxNumber: (Number(record.Propietario_resto) * 0.1).toFixed(2),
                inmueble: record._id,
                serie_id: serie_a_enviar,
                fecha_emision: moment().year(yearQuery).month(monthQuery).endOf("month").format("YYYY-MM-DD"),
                tipo_gestion: "Subarriendo",
              },
              {
                nombreEmisor: "DPD RENTAL MANAGEMENT SL",
                nifEmisor: "B72879711",
                direccionEmisor: "CAMINO FUENTE DE LA JUNQUERA 142",
                cantidad: "1",
                precioUd: (Number(record.emitir_factura) / 1.21).toFixed(2),
                descripcionItem: `Factura Subarriendo Propietario a Empresa - ${record.nombre} - Mes ${monthQuery + 1}/${yearQuery}`,
                nombreReceptor: "DPD RENTAL MANAGEMENT SL",
                serie_id: serie_a_enviar,
                inmueble: record._id,
                fecha_emision: moment().year(yearQuery).month(monthQuery).endOf("month").format("YYYY-MM-DD"),
                tipo_gestion: "Subarriendo",
              },
            ];

            if (record.generar_factura_propietario) {
              facturasSubarriendo.push({
                nombreEmisor: "DPD RENTAL MANAGEMENT SL",
                nifEmisor: "B72879711",
                direccionEmisor: "CAMINO FUENTE DE LA JUNQUERA 142",
                cantidad: "1",
                precioUd: (Number(record.emitir_factura) / 1.21).toFixed(2),
                descripcionItem: `Factura Subarriendo Empresa a Propietario - ${record.nombre} - Mes ${monthQuery + 1}/${yearQuery}`,
                nombreReceptor: record.nombre_facturacion,
                direccionReceptor: record.direccion_facturacion,
                nifReceptor: record.id_facturacion,
                serie_id: serie_a_enviar,
                inmueble: record._id,
                fecha_emision: moment().year(yearQuery).month(monthQuery).endOf("month").format("YYYY-MM-DD"),
                tipo_gestion: "Subarriendo",
              });
            }

            return facturasSubarriendo;
          } else {
            return {
              nombreEmisor: "DPD RENTAL MANAGEMENT SL",
              nifEmisor: "B72879711",
              direccionEmisor: "CAMINO FUENTE DE LA JUNQUERA 142",
              cantidad: "1",
              precioUd: (Number(record.emitir_factura) / 1.21).toFixed(2),
              descripcionItem: `Factura Gestión - ${record.nombre} - Mes ${monthQuery + 1}/${yearQuery}`,
              nombreReceptor: record.nombre_facturacion,
              direccionReceptor: record.direccion_facturacion,
              nifReceptor: record.id_facturacion,
              taxPercentage: "21%",
              taxNumber: ((Number(record.emitir_factura) * 0.21)).toFixed(2),
              inmueble: record._id,
              serie_id: serie_a_enviar,
              fecha_emision: moment().year(yearQuery).month(monthQuery).endOf("month").format("YYYY-MM-DD"),
              tipo_gestion: "Gestión",
            };
          }
        })
      );

      console.log(`Facturas generadas para ${tipo}:`, facturasData);

      const response = await generarFacturas(getAccessTokenApi(), facturasData);
      const result = await response.json();

      if (response.ok) {
        message.success(`Facturas de ${tipo} generadas correctamente`);
      } else {
        message.error(result.error || `Error al generar facturas de ${tipo}`);
      }
    } catch (error) {
      message.error(`Error en la conexión con el servidor al generar facturas de ${tipo}`);
    }

    setLoading(false);
  };

  return (
    <>
      <Button
        type="primary"
        danger
        onClick={() => generarFacturasPorTipo("subarriendo")}
        loading={loading}
        style={{ marginTop: "20px", width: "100%" }}
      >
        Generar Facturas Subarriendo
      </Button>

      <Button
        type="primary"
        danger
        onClick={() => generarFacturasPorTipo("gestion")}
        loading={loading}
        style={{ marginTop: "10px", width: "100%" }}
      >
        Generar Facturas Gestión
      </Button>

      <Collapse accordion>
        {facturas.map((record, index) => (
          <Panel key={index} header={`Propiedad: ${record.nombre}`}>
            {record.tipo_gestion === "Subarriendo" ? (
              <>
                {record.generar_factura_propietario && (
                  <Button
                    style={{ background: "yellow", marginBottom: "5px" }}
                    onClick={() => generarFacturasPorTipo("subarriendo")}
                  >
                    <b>{record.nombre}</b> - Factura Subarriendo Empresa a Propietario
                  </Button>
                )}

                <Button
                  style={{ background: "yellow", marginBottom: "5px" }}
                  onClick={() => generarFacturasPorTipo("subarriendo")}
                >
                  <b>{record.nombre}</b> - Factura Subarriendo Propietario a Empresa
                </Button>

                <Button
                  style={{ background: "yellow", marginBottom: "5px" }}
                  onClick={() => generarFacturasPorTipo("subarriendo")}
                >
                  <b>{record.nombre}</b> - Factura Subarriendo Clientes Varios
                </Button>
              </>
            ) : (
              <Button
                style={{ background: "pink", marginBottom: "5px" }}
                onClick={() => generarFacturasPorTipo("gestion")}
              >
                <b>{record.nombre}</b> - Factura Gestión
              </Button>
            )}

            <Boton_factura_CC record={record} monthQuery={monthQuery} yearQuery={yearQuery} />
          </Panel>
        ))}
      </Collapse>
    </>
  );
};

export default ListaFacturasModal;

import React, { useEffect, useState } from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";

import ReactDOM from "react-dom";
import { PDFViewer, PDFDownloadLink } from "@react-pdf/renderer"; //https://kags.me.ke/post/generate-dynamic-pdf-incoice-using-react-pdf/
import { Col, Divider, Row } from "antd";
// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#ffffff",
    padding: 10,
    width: "100%",
    height: "100%",
  },
  section: {
    margin: 10,
    padding: 0,
  },
  title: {
    marginTop: 40,
    fontSize: 25,
    textAlign: "center",
    marginBottom: 20,
  },
  cajaResumen: {
    marginTop: 40,
    textAlign: "center",
  },

  subtitle: {
    fontSize: 14,
    textAlign: "center",
    marginBottom: 10,
  },
  date: {
    fontSize: 12,
    textAlign: "right",
    marginBottom: 10,
    marginTop: 10,
    marginRight: 30,
  },
  row: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderColor: "#cccccc",
    marginRight: 20,
    marginLeft: 20,
    alignItems: "center",
  },
  row2: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderColor: "#cccccc",
    backgroundColor: "#c2c1c1",
    color: "white",
    marginRight: 20,
    marginLeft: 20,
    alignItems: "center",
  },
  label: {
    fontSize: 10,
    flex: 1,
    fontWeight: "bold",
    textAlign: "center",
  },
  label3: {
    fontSize: 10,
    flex: 1,
    textAlign: "center",
    // transform: "rotate(-90deg)",
  },
  label4: {
    fontSize: 12,
    flex: 2,
    fontWeight: "bold",
    textAlign: "center",
  },
  label2: {
    fontSize: 12,
    flex: 1,
    fontWeight: "bold",
    textAlign: "center",
  },
  item: {
    flex: 1,
    textAlign: "left",
  },
  total: {
    flexDirection: "row",
    marginTop: 10,
    borderTopWidth: 1,
    paddingTop: 5,
    borderColor: "#cccccc",
    alignItems: "flex-end",
  },
  totalLabel: {
    fontWeight: "bold",
    textAlign: "right",
    flex: 1,
  },
  totalAmount: {
    fontWeight: "bold",
    textAlign: "right",
    flex: 1,
  },
  senderBox: {
    borderWidth: 2,
    borderColor: "black",
    padding: 5,
    marginBottom: 5,
    // marginLeft: 30,
    // marginRight: 30,
  },
  receiverBox: {
    borderWidth: 2,
    borderColor: "black",
    padding: 5,
    marginBottom: 50,
    // marginLeft: 30,
    // marginRight: 30,
  },
  sender: {
    marginBottom: 10,
  },
  receiver: {},
  company: {
    fontSize: 15,
    marginBottom: 10,
  },
  companyHeader: {
    fontWeight: "bold",
    fontSize: 16,
    marginBottom: 10,
    textDecoration: "underline",
  },
});

// Create Document Component
export default function FacturasProductos(props) {
  const {
    serie_nombre = "",
    numero_factura,
    fecha_creacion,
    nombre_emisor,
    datos_facturacion_administrador,
    datos_mes,
    inquilinos,
    datos_facturacion,
    elementosFactura,
    direccionEmisor,
    nifEmisor,
    nombreReceptor,
    direccionReceptor,
    nifReceptor,
    proforma,
    nombre_lomo,
    retencion = 0,
    irnr = false,
    comentario = ""
  } = props;
  //console.log("Facturas", datos_facturacion);

  const [factura, setFactura] = useState([]);
  const [facturaTotales, setfacturaTotales] = useState([]);
  const [IVAFactura,setTotalIVAFactura]= useState(0);
  const [BIFactura,setTotalBIFactura]= useState(0);
  const [datos_facturacion_empresa, setdatos_facturacion_empresa] = useState(
    {}
  );
  //console.log("PROPS", props);
  //console.log("inquilinos", inquilinos);
  //console.log("datos_mes", datos_mes);
  //console.log("inquilinos", inquilinos);
  const today = new Date();
  const dateString = today.toLocaleDateString();

  useEffect(() => {
    //console.log("datos_facturacionUseEffect", datos_facturacion_empresa);
    setdatos_facturacion_empresa(datos_facturacion);
    //console.log("datos_facturacionUseEffect2", datos_facturacion_empresa);
    let variable = [];
    let total_bi = 0;
    let total_iva = 0;
    // //console.log("Ahora3", inquilinos);
    elementosFactura.length > 0 ? (
      elementosFactura.forEach((item) => {
       
        const precioUd = parseFloat(item.precio_ud.replace(",", "."));  // Convertir a número con punto decimal
        const cantidad = parseFloat(item.cantidad.replace(",", "."));    // Convertir a número con punto decimal
        const taxNumber = parseFloat(item.tax_number.replace(",", ".")); // Convertir a número con punto decimal
  
        // Asegurarse de que los valores sean números válidos
        if (!isNaN(precioUd) && !isNaN(cantidad) && !isNaN(taxNumber)) {
          total_bi += precioUd * cantidad;
          total_iva += taxNumber;
        }
        variable.push(
          <View style={styles.row}>
            <Text style={styles.label}>
              {item.referencia ?? "referencia"}
            </Text>
            <Text style={styles.label4}>
              {item.descripcion_item ?? "description_item"}
            </Text>
            <Text style={styles.label}>{item.cantidad ?? "cantidad"}</Text>
            <Text style={styles.label}>
              {item.precio_ud ?? "precio_ud"}€
            </Text>
            <Text style={styles.label}>
              {item.tax_percentage ?? "tax_percentage"}
            </Text>
            <Text style={styles.label}>
              {item.tax_number ?? "tax_number"}€
            </Text>
            <Text style={styles.label}>{item.total ?? "total"} €</Text>
          </View>
        );
      })
    ) : (
      <></>
    );
    setFactura(variable);
    variable = [];
    setTotalBIFactura(Number(total_bi).toFixed(2))
    setTotalIVAFactura(Number(total_iva).toFixed(2))
    setfacturaTotales(variable);
  }, [
    inquilinos,
    datos_mes,
    datos_facturacion,
    datos_facturacion_administrador,
    elementosFactura,
  ]);


  const documento = (
    <Document>
      <Page style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.title}>
        
            {proforma ? `Factura Simplificada ${serie_nombre}` : `Factura: ${serie_nombre} ${numero_factura}`}
          </Text>
    
          <Text style={styles.date}>Fecha: {fecha_creacion ?? dateString}</Text>
          <View style={styles.section}>
            <View style={styles.senderBox}>
              <View style={styles.sender}>
                <Text style={styles.companyHeader}>Datos del emisor:</Text>
                <Text style={styles.company}>{nombre_emisor ?? ""}</Text>
                <Text style={styles.company}>{direccionEmisor ?? ""}</Text>
                <Text style={styles.company}>{nifEmisor ?? ""}</Text>
              </View>
            </View>
          </View>
          <View style={styles.section}>
            <View style={styles.receiverBox}>
              <View style={styles.receiver}>
                <Text style={styles.companyHeader}>Datos del receptor:</Text>
                <Text style={styles.company}>{nombreReceptor ?? ""}</Text>
                <Text style={styles.company}>{direccionReceptor ?? ""}</Text>
                <Text style={styles.company}>{nifReceptor ?? ""}</Text>
              </View>
            </View>
          </View>
       </View>
        <View style={styles.section}>
          <View style={styles.row2}>
            <Text style={styles.label2}>Ref.</Text>
            <Text style={styles.label4}>Descrip.</Text>
            <Text style={styles.label2}>Cant.</Text>
            <Text style={styles.label2}>€/ud</Text>

            <Text style={styles.label2}>Impuestos (%)</Text>
            <Text style={styles.label2}>Impuestos</Text>
            <Text style={styles.label2}>Total</Text>
          </View>
        </View>


        {factura}

        <View style={styles.cajaResumen}>
        <View style={styles.senderBox}>
              <View style={styles.receiver}>
                <Text style={styles.companyHeader}>Resumen: {BIFactura ?? ""}€ (BI)+ {IVAFactura ?? ""}€ (IVA)= {Number(Number(IVAFactura ?? 0) + Number(BIFactura??0)).toFixed(2)}€ </Text>
                {Number(retencion)!= 0 ? 
                <>
                  <Text style={styles.companyHeader}>Retencion {irnr ? "IRNR":"IRPF"}: {Number((BIFactura ?? 0)*0.19).toFixed(2)}€</Text>
                  <Text style={styles.companyHeader}>A Ingresar: {Number(Number(IVAFactura ?? 0) + Number(BIFactura??0 )- Number((BIFactura ?? 0)*0.19)).toFixed(2) }€</Text>
                </>
                :
                <></>
                }
              </View>
            </View>
            </View>
         
        <View style={styles.section}>
          <View style={styles.row}></View>
          <Text style={styles.label3}></Text>
          <Text style={styles.label3}>{nombre_lomo ?? ""}</Text>
        </View>
      </Page>
    </Document>
  );



  return (
    <>
      <Row gutter={24}>
        
        <Col span={12}>
        {!proforma?
        <>
        <strong>
        Descargar la factura aqui:
          
        <button style={{
          backgroundColor: "#4CAF50", // Color de fondo verde
          color: "white",              // Color de texto blanco del botón
          padding: "10px 20px",        // Espaciado interno
          border: "none",              // Sin borde
          borderRadius: "5px",         // Bordes redondeados
          cursor: "pointer",           // Cambia el cursor al pasar el ratón
          fontSize: "16px",            // Tamaño de fuente
          transition: "background-color 0.3s" // Transición suave
        }}>
          <PDFDownloadLink 
            document={documento} 
            fileName={`${fecha_creacion.replace("-", "").replace("-", "")}_Factura_${serie_nombre}_${numero_factura}.pdf`}
            style={{ textDecoration: "none" }} // Remueve el subrayado del enlace
          >
            {({ blob, url, loading, error }) =>
              loading ? 
                <span style={{ color: "white" }}>Generando factura...</span> 
              : 
                <span style={{ color: "white" }}>Descargar factura</span>
            }
          </PDFDownloadLink>
        </button>


          </strong>
          </>
          :<></>}
          <PDFViewer
            toolbar={{ download: false }}
            style={{ width: "70vw", height: "100vh" }}
          >
            {documento}
          </PDFViewer>
        
        </Col>
      </Row>
    </>
  );
}

import React, { useState, useEffect } from "react";

import { obtenerListaInmuebles } from "../../../api/inmueble";
import { getAccessTokenApi } from "../../../api/auth";
import TablePlot from "../../../components/Admin/Graphs/Table";
import "antd/dist/antd.css";
import moment from "moment";
import dayjs from "dayjs";
import airbnbIcon from '../../../assets/img/png/airbnb_icon.png';
import bookingIcon from '../../../assets/img/png/booking-icon.png';

import {
  Avatar,
  Form,
  Input,
  Select,
  Button,
  Row,
  Col,
  notification,
  DatePicker,
  Radio,
  Space,
  Divider,
  Modal,
  Card,
} from "antd";
import { FilePdfOutlined } from "@ant-design/icons";
import {
  getFacturacion,
  getFacturacionTotal,
  getFacturacionDatos,
  getFacturacionTotalPorFechas,
} from "../../../api/facturacion";
import Listinquilinosfacturacion from "../../../components/Admin/Users/Listinquilinosfacturacion";
import DemoPie from "../../../components/Admin/Graphs/Pie";

import Facturas from "../../../components/Facturas";
import LectorDni from "../../../components/LectorDni";
import { getGastosApi, getGastosApiMes } from "../../../api/gastos";
import ListGasto from "../../../components/Admin/Users/Gastos/ListGasto/ListGasto";

const { Option } = Select;

export default function FacturacionMes({mes, year, inmueble, busqueda_automatica_al_inicio=false}) {
  const mesActual = dayjs(); // Obtiene la fecha actual con dayjs
  const [num_tours_v, set_num_tours_v] = useState([]);
  const [inmuebleSelected, setInmuebleSelected] = useState(inmueble??"");
  const [datosFacturacion, setDatosFacturacion] = useState([]);

  const [datosFacturacionAdministrador, setDatosFacturacionAdministrador] =
    useState([]);

  const [datosAccum, setDatosAccum] = useState([]);
  const [metricasAccum, setMetricasAccum] = useState([]);

  const [inmueble_facturacion, setinmueble_facturacion] = useState({});

  const [metricasAccumCol, setMetricasAccumCol] = useState([]);
  const [monthQuery, setMonthQuery] = useState(mes??mesActual.month());
  const [yearQuery, setYearQuery] = useState(year??mesActual.year());

  const [usersActive, setUsersActive] = useState([]); //Todos los usuarios
  const [gastos, setgastos] = useState([]); //Todos los usuarios
  const [suma, setsuma] = useState([]); //Todos los usuarios
  const [usersInactive, setUsersInactive] = useState([]);
  const [reloadUsers, setReloadUsers] = useState(false);
  const [selectedValues2, setSelectedValues2] = useState(["Inquilino"]);

  function onChange(date, dateString) {
    setMonthQuery(date.month());
    setYearQuery(date.year());
    //console.log(monthQuery, dateString);
    //console.log(yearQuery, dateString);
  }

  function totalesFacturacion(plataforma, inquilinos){
    console.log("inquilinos", inquilinos)
    let sumaTotalFacturacion = 0;
    let sumaTotalComision = 0;
    let total_inquilinos = 0;
    let sumaTotalLimpieza = 0;
    inquilinos?.forEach(inquilino => {
      if(plataforma?.includes(inquilino?.plataforma) ){
        total_inquilinos = total_inquilinos +1;

      
      sumaTotalFacturacion = sumaTotalFacturacion + Number(inquilino?.pago??0)
      sumaTotalComision = sumaTotalComision + Number(inquilino?.comision??0)
      sumaTotalLimpieza = sumaTotalLimpieza + Number(inquilino?.pago_limpieza??0)?? 0
    
  }});
    return {sumaTotalFacturacion:Number(sumaTotalFacturacion).toFixed(2), sumaTotalComision:Number(sumaTotalComision).toFixed(2), sumaTotalLimpieza: total_inquilinos === 0 ? 0 :Number(Number(sumaTotalLimpieza/total_inquilinos)).toFixed(2)}
  }

  const bookingFacturacion = totalesFacturacion(["Booking.com", "Booking"], usersActive);
  const airbnbFacturacion = totalesFacturacion(["Airbnb"], usersActive);
  const ReferidoFacturacion = totalesFacturacion(["Referido",""], usersActive);

  const [columnasFacturacion, setColumnasFacturacion] = useState([]);
  const [columnasContabilidad, setColumnasContabilidad] = useState([]);
  const [datosPie, setdatosPie] = useState([]);
  const [Factura_visible, setFactura_visible] = useState(false);
  const [datosPiePorcentages, setdatosPiePorcentages] = useState([]);
 
  useEffect(() => {
    const accesToken = getAccessTokenApi();
    getFacturacionDatos(accesToken).then((response) => {
      //console.log("getFacturacionDatos", response);
      setDatosFacturacionAdministrador(response.message);
    });
    getFacturacion(
      accesToken,
      inmuebleSelected,
      monthQuery,
      yearQuery
    ).then((response) => {
      //console.log("getFacturacion", response);
      setinmueble_facturacion(response.inmueble_facturacion);
      setUsersActive(response.users);
      setDatosAccum(response.contabilidad);
      setMetricasAccum(response.metricas);
      let col = [];
      Object.keys(
        response.contabilidad[response.contabilidad.length - 1]
      ).map(function (x) {
        col.push({
          title: x,
          dataIndex: x,
          key: x,
        });
      });
      setColumnasContabilidad(col);
      col = [];
      Object.keys(
        response.metricas[response.metricas.length - 1]
      ).map(function (x) {
        col.push({
          title: x,
          dataIndex: x,
          key: x,
        });
      });
      setMetricasAccumCol(col);
      let variable_aux = Object.keys(
        response.contabilidad[0]
      ).map(function (x) {
        let variable = {
          type: x,
          value: Number(
            parseFloat(response.contabilidad[0][x]).toFixed(2)
          ),
        };
        let variable_porcentajes = {
          type: x,
          value: Number(
            parseFloat(
              parseFloat(response.contabilidad[0][x] * 100) /
                parseFloat(
                  response.contabilidad[0][
                    Object.keys(response.contabilidad[0])[0]
                  ]
                )
            ).toFixed(2)
          ),
        };
        //console.log(variable);
        return variable;
      });
      let variable_aux2 = Object.keys(
        response.contabilidad[0]
      ).map(function (x) {
        let variable_porcentajes = {
          type: x,
          value: Number(
            parseFloat(
              parseFloat(response.contabilidad[0][x] * 100) /
                parseFloat(
                  response.contabilidad[0][
                    Object.keys(response.contabilidad[0])[0]
                  ]
                )
            ).toFixed(2)
          ),
        };
        return variable_porcentajes;
      });
      //console.log(variable_aux);
      setdatosPie(variable_aux);
      setdatosPiePorcentages(variable_aux2);
    });
    obtenerListaInmuebles(accesToken).then((response) => {
      //console.log(response.Pisos[0].id);
      var num_tours_v2 = [];

      let pisos_ordenados = response.Pisos.sort((a, b) => a.nombre.localeCompare(b.nombre));

      for (let i = 0; i < response.Pisos.length; i++) {
        num_tours_v2.push(
          <Option value={pisos_ordenados[i].id}>
            {pisos_ordenados[i].nombre}
          </Option>
        );
      }
      set_num_tours_v(num_tours_v2);
    });

     setReloadUsers(false);
  }, [reloadUsers]);


useEffect(()=>{
  if(busqueda_automatica_al_inicio){
    
    const accesToken = getAccessTokenApi();
    getFacturacionDatos(accesToken).then((response) => {
      //console.log("getFacturacionDatos", response);
      setDatosFacturacionAdministrador(response.message);
    });
    if (inmuebleSelected === "") {
      //console.log("Facturacion_total");
      getFacturacionTotal(
        accesToken,

        monthQuery,
        yearQuery
      ).then((response) => {
        //console.log("getFacturacion", response);
        setinmueble_facturacion(response.inmueble_facturacion);
        setUsersActive(response.users);
        setDatosAccum(response.contabilidad);
        setMetricasAccum(response.metricas);
        let col = [];
        Object.keys(
          response.contabilidad[response.contabilidad.length - 1]
        ).map(function (x) {
          col.push({
            title: x,
            dataIndex: x,
            key: x,
          });
        });
        setColumnasContabilidad(col);
        col = [];
        // Object.keys(
        //   response.metricas[response.metricas.length - 1]
        // ).map(function (x) {
        //   col.push({
        //     title: x,
        //     dataIndex: x,
        //     key: x,
        //   });
        // });
        setMetricasAccumCol([]);
        let variable_aux = Object.keys(
          response.contabilidad[0]
        ).map(function (x) {
          let variable = {
            type: x,
            value: Number(
              parseFloat(response.contabilidad[0][x]).toFixed(2)
            ),
          };
          let variable_porcentajes = {
            type: x,
            value: Number(
              parseFloat(
                parseFloat(response.contabilidad[0][x] * 100) /
                  parseFloat(
                    response.contabilidad[0][
                      Object.keys(response.contabilidad[0])[0]
                    ]
                  )
              ).toFixed(2)
            ),
          };
          //console.log(variable);
          return variable;
        });
        let variable_aux2 = Object.keys(
          response.contabilidad[0]
        ).map(function (x) {
          let variable_porcentajes = {
            type: x,
            value: Number(
              parseFloat(
                parseFloat(response.contabilidad[0][x] * 100) /
                  parseFloat(
                    response.contabilidad[0][
                      Object.keys(response.contabilidad[0])[0]
                    ]
                  )
              ).toFixed(2)
            ),
          };

          return variable_porcentajes;
        });
        //console.log(variable_aux);
        setdatosPie(variable_aux);
        setdatosPiePorcentages(variable_aux2);
      });
    } else {
      getFacturacion(
        accesToken,
        inmuebleSelected,
        monthQuery,
        yearQuery
      ).then((response) => {
        //console.log("getFacturacion", response);
        setinmueble_facturacion(response.inmueble_facturacion);
        setUsersActive(response.users);
        setDatosAccum(response.contabilidad);
        setMetricasAccum(response.metricas);
        let col = [];
        Object.keys(
          response.contabilidad[response.contabilidad.length - 1]
        ).map(function (x) {
          col.push({
            title: x,
            dataIndex: x,
            key: x,
          });
        });
        setColumnasContabilidad(col);
        col = [];
        Object.keys(
          response.metricas[response.metricas.length - 1]
        ).map(function (x) {
          col.push({
            title: x,
            dataIndex: x,
            key: x,
          });
        });
        setMetricasAccumCol(col);
        let variable_aux = Object.keys(
          response.contabilidad[0]
        ).map(function (x) {
          let variable = {
            type: x,
            value: Number(
              parseFloat(response.contabilidad[0][x]).toFixed(2)
            ),
          };
          let variable_porcentajes = {
            type: x,
            value: Number(
              parseFloat(
                parseFloat(response.contabilidad[0][x] * 100) /
                  parseFloat(
                    response.contabilidad[0][
                      Object.keys(response.contabilidad[0])[0]
                    ]
                  )
              ).toFixed(2)
            ),
          };
          //console.log(variable);
          return variable;
        });
        let variable_aux2 = Object.keys(
          response.contabilidad[0]
        ).map(function (x) {
          let variable_porcentajes = {
            type: x,
            value: Number(
              parseFloat(
                parseFloat(response.contabilidad[0][x] * 100) /
                  parseFloat(
                    response.contabilidad[0][
                      Object.keys(response.contabilidad[0])[0]
                    ]
                  )
              ).toFixed(2)
            ),
          };
          return variable_porcentajes;
        });
        //console.log(variable_aux);
        setdatosPie(variable_aux);
        setdatosPiePorcentages(variable_aux2);
      });

      getGastosApiMes(
        accesToken,
        inmuebleSelected,
        monthQuery,
        yearQuery
      ).then((response) => {
        setgastos(response.gasto);
        setsuma(response.suma);
        //console.log("getGastosApiMes", response);
      });
    }
  
  }
},[])
  return (
    <>
      {/* <Button
        type="primary"
        onClick={() => {
          setFactura_visible(!Factura_visible);
        }}
      >
        {!Factura_visible ? "Mostrar Factura" : "Ocultar Factura"}{" "}
        <FilePdfOutlined />
      </Button> */}
      {Factura_visible ? (
        <Row gutter={24}>
          <Col span={20}>
            <Facturas
              datos_facturacion_administrador={
                datosFacturacionAdministrador ?? []
              }
              datos_mes={datosAccum ?? []}
              inquilinos={usersActive ?? []}
              datos_facturacion={inmueble_facturacion ?? {}}
            />
          </Col>
        </Row>
      ) : (
        <></>
      )}
      {/* <LectorDni /> */}
      <div style={{ padding: "50px" }}>
        <Row gutter={24}>
          <Col span={8}>
            <Select
            style={{minWidth:"200px"}}
             defaultValue={inmuebleSelected} // <== Añadir esta línea
              onChange={(e) => {
                setInmuebleSelected(e);
                //console.log(inmuebleSelected);
              }}
              placeholder={"Selecciona un inmueble"}
            >
              {num_tours_v}
            </Select>
          </Col>
          <Col span={8}>
            <DatePicker
              onChange={onChange}
              picker="month"
              defaultValue={dayjs().month(monthQuery).year(yearQuery)} // Mantener esta línea
            />
          </Col>
          <Col span={8}>
            <Button
              onClick={() => {
                const accesToken = getAccessTokenApi();
                getFacturacionDatos(accesToken).then((response) => {
                  //console.log("getFacturacionDatos", response);
                  setDatosFacturacionAdministrador(response.message);
                });
                if (inmuebleSelected === "") {
                  //console.log("Facturacion_total");
                  getFacturacionTotal(
                    accesToken,

                    monthQuery,
                    yearQuery
                  ).then((response) => {
                    //console.log("getFacturacion", response);
                    setinmueble_facturacion(response.inmueble_facturacion);
                    setUsersActive(response.users);
                    setDatosAccum(response.contabilidad);
                    setMetricasAccum(response.metricas);
                    let col = [];
                    Object.keys(
                      response.contabilidad[response.contabilidad.length - 1]
                    ).map(function (x) {
                      col.push({
                        title: x,
                        dataIndex: x,
                        key: x,
                      });
                    });
                    setColumnasContabilidad(col);
                    col = [];
                    // Object.keys(
                    //   response.metricas[response.metricas.length - 1]
                    // ).map(function (x) {
                    //   col.push({
                    //     title: x,
                    //     dataIndex: x,
                    //     key: x,
                    //   });
                    // });
                    setMetricasAccumCol([]);
                    let variable_aux = Object.keys(
                      response.contabilidad[0]
                    ).map(function (x) {
                      let variable = {
                        type: x,
                        value: Number(
                          parseFloat(response.contabilidad[0][x]).toFixed(2)
                        ),
                      };
                      let variable_porcentajes = {
                        type: x,
                        value: Number(
                          parseFloat(
                            parseFloat(response.contabilidad[0][x] * 100) /
                              parseFloat(
                                response.contabilidad[0][
                                  Object.keys(response.contabilidad[0])[0]
                                ]
                              )
                          ).toFixed(2)
                        ),
                      };
                      //console.log(variable);
                      return variable;
                    });
                    let variable_aux2 = Object.keys(
                      response.contabilidad[0]
                    ).map(function (x) {
                      let variable_porcentajes = {
                        type: x,
                        value: Number(
                          parseFloat(
                            parseFloat(response.contabilidad[0][x] * 100) /
                              parseFloat(
                                response.contabilidad[0][
                                  Object.keys(response.contabilidad[0])[0]
                                ]
                              )
                          ).toFixed(2)
                        ),
                      };

                      return variable_porcentajes;
                    });
                    //console.log(variable_aux);
                    setdatosPie(variable_aux);
                    setdatosPiePorcentages(variable_aux2);
                  });
                } else {
                  getFacturacion(
                    accesToken,
                    inmuebleSelected,
                    monthQuery,
                    yearQuery
                  ).then((response) => {
                    //console.log("getFacturacion", response);
                    setinmueble_facturacion(response.inmueble_facturacion);
                    setUsersActive(response.users);
                    setDatosAccum(response.contabilidad);
                    setMetricasAccum(response.metricas);
                    let col = [];
                    Object.keys(
                      response.contabilidad[response.contabilidad.length - 1]
                    ).map(function (x) {
                      col.push({
                        title: x,
                        dataIndex: x,
                        key: x,
                      });
                    });
                    setColumnasContabilidad(col);
                    col = [];
                    Object.keys(
                      response.metricas[response.metricas.length - 1]
                    ).map(function (x) {
                      col.push({
                        title: x,
                        dataIndex: x,
                        key: x,
                      });
                    });
                    setMetricasAccumCol(col);
                    let variable_aux = Object.keys(
                      response.contabilidad[0]
                    ).map(function (x) {
                      let variable = {
                        type: x,
                        value: Number(
                          parseFloat(response.contabilidad[0][x]).toFixed(2)
                        ),
                      };
                      let variable_porcentajes = {
                        type: x,
                        value: Number(
                          parseFloat(
                            parseFloat(response.contabilidad[0][x] * 100) /
                              parseFloat(
                                response.contabilidad[0][
                                  Object.keys(response.contabilidad[0])[0]
                                ]
                              )
                          ).toFixed(2)
                        ),
                      };
                      //console.log(variable);
                      return variable;
                    });
                    let variable_aux2 = Object.keys(
                      response.contabilidad[0]
                    ).map(function (x) {
                      let variable_porcentajes = {
                        type: x,
                        value: Number(
                          parseFloat(
                            parseFloat(response.contabilidad[0][x] * 100) /
                              parseFloat(
                                response.contabilidad[0][
                                  Object.keys(response.contabilidad[0])[0]
                                ]
                              )
                          ).toFixed(2)
                        ),
                      };
                      return variable_porcentajes;
                    });
                    //console.log(variable_aux);
                    setdatosPie(variable_aux);
                    setdatosPiePorcentages(variable_aux2);
                  });

                  getGastosApiMes(
                    accesToken,
                    inmuebleSelected,
                    monthQuery,
                    yearQuery
                  ).then((response) => {
                    setgastos(response.gasto);
                    setsuma(response.suma);
                    //console.log("getGastosApiMes", response);
                  });
                }
              }}
            >
              {" "}
              Buscar
            </Button>
          </Col>


        </Row>
      </div>
      <div style={{ width: "100%", height: "100%" }}>
        <Row style={{ width: "100%", height: "100%" }} gutter={24}>
         
        {datosFacturacion.length > 0 ? (
            <TablePlot
              data={datosAccum}
              columns={columnasContabilidad}
              style={{ width: "100%", height: "100%" }}
            />
          ) : (
            <></>
          )}
        </Row>
      </div>
      <Row style={{ width: "100%", height: "100%" }} gutter={24}>
        {metricasAccum?.length > 0 ? (
          <TablePlot
            data={metricasAccum}
            columns={metricasAccumCol}
            style={{ width: "100%", height: "100%" }}
          />
        ) : (
          <></>
        )}
      </Row>
      {datosPie.length > 0 ? (
        <Row gutter={24}>
          <Col span={12}>
            <DemoPie
              data_real={datosPie.slice(1)}
              my_title={"Total: " + datosPie[0].value}
            />
          </Col>
          <Col span={12}>
            <DemoPie
              data_real={datosPiePorcentages.slice(1)}
              my_title={"Total: " + datosPiePorcentages[0].value + "%"}
            />
          </Col>
        </Row>
      ) : (
        <></>
      )}
      <h2>Todos los gastos suman: {suma}€</h2>
      <Row gutter={16} style={{ marginTop: 20 }}>
      <Col span={8}>
        <Card
          title={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img src={airbnbIcon} alt="Airbnb Icon" style={{ width: '20px', height: '20px', marginRight: '8px' }} />
              <span>Airbnb</span>
            </div>
          }
          bordered={true}
          style={{ borderRadius: 10, boxShadow: '0px 4px 12px rgba(0,0,0,0.1)' }}
        >
          <p>Facturación: <strong>{airbnbFacturacion.sumaTotalFacturacion ?? "0"}€</strong></p>
          <p>Comisión: <strong>{airbnbFacturacion.sumaTotalComision ?? "0"}€</strong></p>
          <p>Limpieza:  <strong>{airbnbFacturacion.sumaTotalLimpieza ?? "0"}€</strong></p>
        </Card>
      </Col>
      <Col span={8}>
        <Card
           title={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img src={bookingIcon} alt="Booking Icon" style={{ width: '20px', height: '20px', marginRight: '8px' }} />
              <span>Booking</span>
            </div>
          }
          bordered={true}
          style={{ borderRadius: 10, boxShadow: '0px 4px 12px rgba(0,0,0,0.1)' }}
        >
          <p>Facturación:<strong> {bookingFacturacion.sumaTotalFacturacion ?? "0"}€</strong></p>
          <p>Comisión:  <strong>{bookingFacturacion.sumaTotalComision ?? "0"}€</strong></p>
          <p>Limpieza:  <strong>{bookingFacturacion.sumaTotalLimpieza ?? "0"}€</strong></p>
        </Card>
      </Col>
      <Col span={8}>
        <Card
          title="Referido"
          bordered={true}
          style={{ borderRadius: 10, boxShadow: '0px 4px 12px rgba(0,0,0,0.1)' }}
        >
          <p>Facturación:<strong> {ReferidoFacturacion.sumaTotalFacturacion ?? "0"}€</strong></p>
          <p>Comisión:  <strong>{ReferidoFacturacion.sumaTotalComision ?? "0"}€</strong></p>
          <p>Limpieza:  <strong>{ReferidoFacturacion.sumaTotalLimpieza ?? "0"}€</strong></p>
        </Card>
      </Col>
    </Row>
    <Divider></Divider>
      <Listinquilinosfacturacion
        usersActive={usersActive}
        usersInactive={usersInactive}
        setReloadUsers={setReloadUsers}
        setSelectedValues={setSelectedValues2}
      />
      <br></br>

      <ListGasto
        usersActive={gastos}
        usersInactive={usersInactive}
        setReloadUsers={setReloadUsers}
        fechas_busqueda={[]}
        setfechas_busqueda={null}
      />
    </>
  );
}

import React, { useState, useEffect } from "react";

import { obtenerListaInmuebles } from "../../../api/inmueble";
import { getAccessTokenApi } from "../../../api/auth";
import TablePlot from "../../../components/Admin/Graphs/Table";
import "antd/dist/antd.css";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import ListaFacturasModal from "./componentes_facturacion/ListaFacturasModal";


import {
  Avatar,
  Form,
  Input,
  Select,
  Button,
  Row,
  Col,
  notification,
  DatePicker,
  Radio,
  Space,
  Divider,
  Modal,
  Collapse,
  Table,
} from "antd";
import moment from "moment";
import { FilePdfOutlined } from "@ant-design/icons";
import { getFacturacion, getFacturacionTotal, getFacturacionTotalPorFechas } from "../../../api/facturacion";
import Listinquilinosfacturacion from "../../../components/Admin/Users/Listinquilinosfacturacion";
import DemoPie from "../../../components/Admin/Graphs/Pie";

import Facturas from "../../../components/Facturas";
import LectorDni from "../../../components/LectorDni";
import Gastos from "../Gastos";
import ListGasto from "../../../components/Admin/Users/Gastos/ListGasto";
import DesplegableMultiple from "../../../components/DesplegableMultiple";
import Title from "antd/lib/skeleton/Title";
import Tabla_global from "../FacturacionMes_Tabla_reporte/tabla_global";
import { render } from "react-dom";
import CalculosGlobalesPiso from "./componentes_facturacion/CalculosGlobalesPiso";
import { getReservasDuplicadas } from "../../../api/reservas_duplicadas";
import ReservasDuplicadas from "./componentes_facturacion/ReservasDuplicadas";
import GenerarRemesaNorma34 from "./componentes_facturacion/GenerarRemesaNorma34";
import Duplicadas from "./componentes_facturacion/Duplicadas";
import { getSeries, getSeriesInmueble } from "../../../api/series";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";
import Calculadora_rentabilidad from "./componentes_facturacion/Calculadora_rentabilidad";
import Boton_factura_CC from "./componentes_facturacion/Boton_factura_CC";
import Boton_factura_gestion_CC from "./componentes_facturacion/Boton_factura_gestion_CC";

const { Option } = Select;
const { RangePicker } = DatePicker;
export default function FacturacionMes() {
  
  const [filteredValue, setFilteredValue] = useState(['Gestion_bruta']);
  const [fechas_busqueda, setfechas_busqueda] = useState([
    moment().subtract(30, "days"),
    moment(),
  ]);

  const [num_tours_v, set_num_tours_v] = useState([]);
  const [inmuebleSelected, setInmuebleSelected] = useState("");
  const [datosFacturacion, setDatosFacturacion] = useState([]);

  const [datosAccum, setDatosAccum] = useState([]);
  const [datosAccumPorCiudad, setdatosAccumPorCiudad] = useState([]);
  const [metricasAccum, setMetricasAccum] = useState([]);
  const [numero_reservas, setnumero_reservas] = useState(0);
  const [pisos_generadores_dinero, setpisos_generadores_dinero] = useState([]);

  const [inmueble_facturacion, setinmueble_facturacion] = useState({});
  const [serie_id, setserie_id] = useState("");
  const [ciudad_remesa, setciudad_remesa] = useState([]);

  const [metricasAccumCol, setMetricasAccumCol] = useState([]);
  const [datos_facturacion_por_piso, setdatos_facturacion_por_piso] = useState(
    []
  );
  const [datos_facturacion_por_pisoCol, setdatos_facturacion_por_pisoCol] =
    useState([]);

  const [porcentaje_facturacion_por_piso, setporcentaje_facturacion_por_piso] =
    useState([]);
  const [
    porcentaje_facturacion_por_pisoCol,
    setporcentaje_facturacion_por_pisoCol,
  ] = useState([]);
  const [monthQuery, setMonthQuery] = useState(moment().month());
  const [yearQuery, setYearQuery] = useState(moment().year());

  const [usersActive, setUsersActive] = useState([]); //Todos los usuarios
  const [usersInactive, setUsersInactive] = useState([]);
  const [reloadUsers, setReloadUsers] = useState(false);
  const [selectedValues2, setSelectedValues2] = useState(["Inquilino"]);
  const [series, setseries] = useState({});

  // Manejar cambios en la tabla
const handleChange = (pagination, filters, sorter) => {
  console.log("Filtros aplicados:", filters);
  setFilteredValue(filters.tipo_gestion || []); // Actualiza el valor filtrado cuando el usuario cambie el filtro
};
  const formatCurrency = (value) => {
    return new Intl.NumberFormat('es-ES', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2,
    }).format(value);
  };
  const calcularIngresoPropietario = (Propietario_gestion, precio_mantenimientos, pago_limpieza, propietario_paga_limpieza = false) => {
    
    if (propietario_paga_limpieza) {
      return formatCurrency( Propietario_gestion - precio_mantenimientos + pago_limpieza);
    } else {
      return formatCurrency(Propietario_gestion - precio_mantenimientos);
    }
  };

  // Función para copiar al portapapeles
const copiarAlPortapapeles = (record) => {
  console.log("copiarAlPortapapeles", record)
  // Convertir los datos del record a un formato de texto
  // const textoACopiar = `
  //   ${record.iban};;;${record.nombre_facturacion};${record.direccion_facturacion};;${record.ciudad};;;;${record.id_facturacion};${record.ingresoPropietario};`;
  
  // // Copiar el texto al portapapeles
  // navigator.clipboard.writeText(textoACopiar).then(() => {
  //   // message.success('Datos copiados al portapapeles');
  // }).catch(() => {
  //   // message.error('Error al copiar los datos');
  // });
   const textoACopiar = `${record}`;
    
  // Copiar el texto al portapapeles
  navigator.clipboard.writeText(textoACopiar).then(() => {
    // message.success('Datos copiados al portapapeles');
  }).catch(() => {
    // message.error('Error al copiar los datos');
  });
};
  
useEffect(()=>{
  getSeries(getAccessTokenApi()).then((response) => {
    var num_tours_v2 = [];
   
    //console.log(response);
    for (let i = 0; i < response.series.length; i++) {
      num_tours_v2.push(
        <Option value={response.series[i]._id}>
          {response.series[i].nombre_serie}
        </Option>
      );
   
    }
    //console.log(numeros_series);
   
    setseries(num_tours_v2);
  });
},[])
  // useEffect(()=>{
    
  //   console.log("filteredValue", filteredValue)
  // },[filteredValue])

  function onChange(date, dateString) {
    setMonthQuery(date.month());
    setYearQuery(date.year());
    //console.log(monthQuery, dateString);
    //console.log(yearQuery, dateString);
  }
  const [columnasFacturacion, setColumnasFacturacion] = useState([]);
  const [columnasContabilidad, setColumnasContabilidad] = useState([]);
  const [datosPie, setdatosPie] = useState([]);
  const [Factura_visible, setFactura_visible] = useState(false);
  const [datosPiePorcentages, setdatosPiePorcentages] = useState([]);
  const [datosPiePorcentajePisos, setDatosPiePorcentajePisos] = useState([]);
  const [datosPiePisos, setDatosPiePisos] = useState([]);
  const [tituloValorTotal, setTituloValorTotal] = useState("");
  const [reservasDuplicadas, setreservasDuplicadas] = useState("");

  useEffect(() => {
    const accesToken = getAccessTokenApi();
    //console.log("A VER QUE ME MUESTRAS DE FECHAS ", monthQuery)
    obtenerListaInmuebles(accesToken).then((response) => {
      //console.log(response.Pisos[0].id);
      var num_tours_v2 = [];
      let pisos_ordenados = response.Pisos.sort((a, b) => a.nombre.localeCompare(b.nombre));

      for (let i = 0; i < response.Pisos.length; i++) {
        num_tours_v2.push(
          <Option value={pisos_ordenados[i].id}>
            {pisos_ordenados[i].nombre}
          </Option>
        );
      }
      set_num_tours_v(num_tours_v2);
    });
    getReservasDuplicadas(accesToken,monthQuery, yearQuery).then(
      (response)=>{
        setreservasDuplicadas(response)
        notification["success"]({
          message: "Informacion " + response.detallesCSV
        });
      }
    )
    getFacturacion(accesToken, inmuebleSelected, monthQuery, yearQuery).then(
      (response) => {
        //console.log("getFacturacion", response);

        setUsersActive(response.users);
        setDatosAccum(response.contabilidad);
        setMetricasAccum(response.metricas);
        setnumero_reservas(response.numero_reservas);
        let col = [];
        Object.keys(
          response.contabilidad[response.contabilidad.length - 1]
        ).map(function (x) {
          col.push({
            title: x,
            dataIndex: x,
            key: x,
          });
        });

        setColumnasContabilidad(col);
        col = [];
        Object.keys(response.metricas).map(function (x) {
          col.push({
            title: x,
            dataIndex: x,
            key: x,
          });
        });
        setMetricasAccumCol(col);
        let variable_aux = Object.keys(response.contabilidad[0]).map(function (
          x
        ) {
          let variable = {
            type: x,
            value: Number(parseFloat(response.contabilidad[0][x]).toFixed(2)),
          };
          let variable_porcentajes = {
            type: x,
            value:
              (Number(parseFloat(response.contabilidad[0][x]).toFixed(2)) *
                100) /
              Number(
                parseFloat(
                  response.contabilidad[0][
                  Object.keys(response.contabilidad[0])
                  ]
                ).toFixed(2)
              ),
          };
          //console.log(variable);
          return variable_porcentajes;
        });
        //console.log(variable_aux);
        setdatosPie(variable_aux);
      }
    );
    setReloadUsers(false);
  }, [reloadUsers]);

useEffect(()=>{},[datosAccumPorCiudad])
  function onClickPorFechas() {
    const accesToken = getAccessTokenApi();

    //console.log("Facturacion_total");
    getFacturacionTotalPorFechas(accesToken, monthQuery, yearQuery, fechas_busqueda).then(
      (response) => {
        //console.log("getFacturacion", response);
        setinmueble_facturacion(response.inmueble_facturacion);
        setUsersActive(response.users);

        setDatosAccum(response.contabilidad);
        setMetricasAccum(response.metricas);
        setnumero_reservas(response.numero_reservas);
        let col = [];
        Object.keys(
          response.contabilidad[response.contabilidad.length - 1]
        ).map(function (x) {
          col.push({
            title: x,
            dataIndex: x,
            key: x,
            
          });
        });
        setColumnasContabilidad(col);
        col = [];
        //console.log("columnita 2: ", col);
        Object.keys(response.users[response.users.length - 1]).map(
          function (x) {
           
            col.push({
              title: x,
              dataIndex: x,
              key: x,
              // render: (text, record) => {
              //   // Marcar en rojo las categorías relacionadas con "Bruto" y "Beneficio Operativo"
                
              //   return <Collapse>
              //   <Collapse.Panel header="Click me">
              //     Hola k ase
              //   </Collapse.Panel>
              // </Collapse>;
              // },
            });
          }
        );
        //console.log("columnita: ", col);
        setMetricasAccumCol([]);

        setdatos_facturacion_por_pisoCol(col);
        setdatos_facturacion_por_piso(response.users.sort((a, b) => a.nombre.localeCompare(b.nombre)));

        let variable_aux = Object.keys(
          response.contabilidad[0]
        ).map(function (x) {
          let variable = {
            type: x,
            value: Number(
              parseFloat(response.contabilidad[0][x]).toFixed(2)
            ),
          };
          let variable_porcentajes = {
            type: x,
            value: Number(
              parseFloat(
                parseFloat(response.contabilidad[0][x] * 100) /
                parseFloat(
                  response.contabilidad[0][
                  Object.keys(response.contabilidad[0])[0]
                  ]
                )
              ).toFixed(2)
            ),
          };
          //console.log(variable);
          return variable;
        });
        let variable_aux2 = Object.keys(
          response.contabilidad[0]
        ).map(function (x) {
          let variable_porcentajes = {
            type: x,
            value: Number(
              parseFloat(
                parseFloat(response.contabilidad[0][x] * 100) /
                parseFloat(
                  response.contabilidad[0][
                  Object.keys(response.contabilidad[0])[0]
                  ]
                )
              ).toFixed(2)
            ),
          };
 
          return variable_porcentajes;
        });
        //console.log(variable_aux);
        setdatosPie(variable_aux);
        //console.log("datosPie",variable_aux)
        setdatosPiePorcentages(variable_aux2);
        const totalFacturacion = response.users.reduce((total, piso) => {
          return total + piso.Facturacion;
        }, 0);

        const datosPiePorcentajePisos = response.users.map(piso => {
          const porcentaje = (piso.ComoTuCasa / totalFacturacion) * 100;
          return {
            nombre: piso.nombre,
            porcentaje: parseFloat(porcentaje.toFixed(2)) // Redondea a dos decimales
          };
        });

        setDatosPiePorcentajePisos(datosPiePorcentajePisos);

        const totalComoTuCasa = response.users.reduce((total, user) => {
          return total + Number(user.ComoTuCasa);
        }, 0);

        const datosPieComoTuCasa = response.users.map(user => {
          return {
            nombre: user.nombre,
            value: Number(user.ComoTuCasa) // Usa el valor absoluto de la facturación
          };
        });
        const titulo = `Total: ${totalComoTuCasa.toFixed(2)} €`;
        setTituloValorTotal(titulo);
        setDatosPiePisos(datosPieComoTuCasa);
      }
    );
  }

  function filtrar_ciudad(informacion_pisos_mes, ciudades) {
    let acumulados1 = {
        Facturacion: 0,
        reservas: 0,
        dias: 0
    };

    let acumulados2 = [
        { type: 'pago_limpieza', value: 0 },
        { type: 'Comision', value: 0 },
        { type: 'ComoTuCasa', value: 0 },
        { type: 'Propietario_gestion', value: 0 },
        { type: 'Propietario_resto', value: 0 },
        { type: 'IVA_ComoTuCasa', value: 0 }
    ];
    let acumulados3 = [
      { type: 'pago_limpieza', value: 0 },
      { type: 'Comision', value: 0 },
      { type: 'ComoTuCasa', value: 0 },
      { type: 'Propietario_gestion', value: 0 },
      { type: 'Propietario_resto', value: 0 },
      { type: 'IVA_ComoTuCasa', value: 0 }
  ];


    ciudades.forEach(ciudad => {
        let pisos_ciudad = informacion_pisos_mes?.filter(item => item.ciudad === ciudad);

        pisos_ciudad?.forEach(element => {
            for (let key in acumulados1) {
                if (element[key]) {
                    acumulados1[key] += parseFloat(element[key]);
                }
            }
            acumulados2.forEach(obj => {
                if (element[obj.type])
                    obj.value += parseFloat(element[obj.type]);
            });
        });
    });

    for (let key in acumulados1) {
        acumulados1[key] = parseFloat(acumulados1[key].toFixed(2));
    }

    acumulados2.forEach((obj,index) => {
        obj.value = parseFloat(obj.value.toFixed(2));
        console.log("accum3", obj.type, obj.value)
        console.log("accum3", acumulados3)
        acumulados3[index].value =  parseFloat((parseFloat(obj.value)*100/parseFloat(acumulados1.Facturacion)).toFixed(2));
    });

    let result = { generales: acumulados1, operativas: acumulados2, operativas_percentaje:acumulados3 };
    console.log("result",result)
    return result;
}



  function onClickMeses() {
    const accesToken = getAccessTokenApi();
    getReservasDuplicadas(accesToken,monthQuery, yearQuery).then(
      (response)=>{
        setreservasDuplicadas(response)
        // notification["success"]({
        //   message: "Exito en abrir la puerta en " +response+ JSON.stringify(response)
        // });
      }
    )
    //console.log("Facturacion_total");
    //console.log("Mes y año",monthQuery,yearQuery);
    getFacturacionTotal(accesToken, monthQuery, yearQuery).then(
      (response) => {
        if(response.error){
          notification["error"]({
            message: response.error
          });
          return;
        }
        //console.log("getFacturacion", response);
        setinmueble_facturacion(response.inmueble_facturacion);
        setUsersActive(response.users);
       


        setDatosAccum(response.contabilidad);
        setnumero_reservas(response.numero_reservas);
        setMetricasAccum(response.metricas);
        let col = [];
        Object.keys(
          response.contabilidad[response.contabilidad.length - 1]
        ).map(function (x) {
          console.log("XXXXX", x)
          col.push({
            title: x,
            dataIndex: x,
            key: x,
          });
        });
        setColumnasContabilidad(col);
        col = [];
        //console.log("columnita 2: ", col);
        Object.keys(response.users[response.users.length - 1]).map(
          function (x) {
            console.log("XXXXX1", x)
            if(x === "tipo_gestion"){
            col.push({
              title: x,
              dataIndex: x,
              key: x,
              // render: (text, record) => (
              //   <Collapse>
              //     <Collapse.Panel header={text || "Ver más"}>
              //       <p>{`Detalles de ${text}`}</p>
              //       {/* Puedes añadir más detalles o contenido aquí */}
              //     </Collapse.Panel>
              //   </Collapse>
              // ),
              filters: [
                { text: 'Gestion_bruta', value: 'Gestion_bruta' },
                { text: 'Subarriendo', value: "Subarriendo" },
              ],
              // filteredValue: filteredValue || null, // Asigna el valor por defecto al filtro
              // filteredValue: filteredValue || null,
              onFilter: (value, record) => {
                console.log("Filtrando por:", value, record);
                return record.tipo_gestion.toString() === value.toString();
              },
             
             
            });}else if(x === "propietario_paga_limpieza"){
              col.push({
                title: x,
                dataIndex: x,
                key: x,
                // render: (text, record) => (
                //   <Collapse>
                //     <Collapse.Panel header={text || "Ver más"}>
                //       <p>{`Detalles de ${text}`}</p>
                //       {/* Puedes añadir más detalles o contenido aquí */}
                //     </Collapse.Panel>
                //   </Collapse>
                // ),
                render: (text, record) => {
                  // Estilo condicional para la columna de Total
                  
                  return text ? "Paga propietario":"-";
                },
               
              });}else{
              col.push({
                title: x,
                dataIndex: x,
                key: x,
                // render: (text, record) => (
                //   <Collapse>
                //     <Collapse.Panel header={text || "Ver más"}>
                //       <p>{`Detalles de ${text}`}</p>
                //       {/* Puedes añadir más detalles o contenido aquí */}
                //     </Collapse.Panel>
                //   </Collapse>
                // ),
                
              });
            }
          }
        );
       
        //console.log("columnita: ", col);
        setMetricasAccumCol([]);

        setdatos_facturacion_por_pisoCol(col);
        setdatos_facturacion_por_piso(response.users.sort((a, b) => a.nombre.localeCompare(b.nombre)));

      

        let variable_aux = Object.keys(
          response.contabilidad[0]
        ).map(function (x) {
          let variable = {
          
            type: x,
            value: Number(
              parseFloat(response.contabilidad[0][x]).toFixed(2)
            ),
          };
          let variable_porcentajes = {
            type: x,
            value: Number(
              parseFloat(
                parseFloat(response.contabilidad[0][x] * 100) /
                parseFloat(
                  response.contabilidad[0][
                  Object.keys(response.contabilidad[0])[0]
                  ]
                )
              ).toFixed(2)
            ),
          };
          //console.log(variable);
          return variable;
        });
        let variable_aux2 = Object.keys(
          response.contabilidad[0]
        ).map(function (x) {
          let variable_porcentajes = {
            type: x,
            value: Number(
              parseFloat(
                parseFloat(response.contabilidad[0][x] * 100) /
                parseFloat(
                  response.contabilidad[0][
                  Object.keys(response.contabilidad[0])[0]
                  ]
                )
              ).toFixed(2)
            ),
          };

          return variable_porcentajes;
        });
        //console.log("variable_aux",variable_aux);
        setdatosPie(variable_aux);
        // setdatosPie(variable_aux.filter(item => item.ciudad === "ZGZ"));

        setdatosPiePorcentages(variable_aux2);

        const totalFacturacion = response.users.reduce((total, piso) => {
          return total + piso.Facturacion;
        }, 0);

        const datosPiePorcentajePisos = response.users .filter(item => item.ComoTuCasa > 0).map(piso => {
          const porcentaje = (piso.ComoTuCasa / totalFacturacion) * 100;
          return {
            nombre: piso.nombre,
            porcentaje: parseFloat(porcentaje.toFixed(2)) // Redondea a dos decimales
          };
        }).sort((a, b) => b.porcentaje - a.porcentaje) // Ordena de mayor a menor porcentaje;
        //console.log("datosPiePorcentajePisos", datosPiePorcentajePisos)
        setDatosPiePorcentajePisos(datosPiePorcentajePisos);

        const totalComoTuCasa = response.users.reduce((total, user) => {
          return total + Number(user.ComoTuCasa);
        }, 0);

        const datosPieComoTuCasa = response.users.map(user => {
          return {
            nombre: user.nombre,
            value: Number(user.ComoTuCasa), // Usa el valor absoluto de la facturación
            reservas: user.reservas,
            tipo_gestion: user.tipo_gestion,
            ciudad: user.ciudad
          };
        });

        setDatosPiePisos(datosPieComoTuCasa);
        const titulo = `Total: ${totalComoTuCasa.toFixed(2)} €`;
        setTituloValorTotal(titulo);
        //console.log("Datos Pisos facturacion  ", datosPiePorcentages);
        //console.log("Datos Pisos facturacion porcentajes ", datosPiePorcentajePisos);
        //console.log("Datos Pisos total ", datosPiePisos);

      }

    );
  }


  const defaultValueMonth = moment().set({ 'year': yearQuery, 'month': monthQuery });

  const dataParaPie = datosPiePorcentajePisos
    .filter(item => item.porcentaje > 0) // Filtra los elementos con porcentaje mayor a 0
    .sort((a, b) => b.porcentaje - a.porcentaje) // Ordena de mayor a menor porcentaje
    .map(item => ({ // Mapea los elementos a la estructura deseada
      type: item.nombre,
      value: item.porcentaje
    }));

  const dataParaPieDirecto = datosPiePisos
    .filter(item => item.value > 0) // Filtra los elementos con valor mayor a 0
    .sort((a, b) => b.value - a.value) // Ordena de mayor a menor valor
    .map(item => ({ // Mapea los elementos a la estructura deseada
      type: item.nombre,
      value: item.value
    }));

    const numero_pisos_generadores_dinero = datosPiePisos
    .filter(item => item.value > 0).sort((a, b) => b.value - a.value);
    const numero_pisos_no_generadores_dinero = datosPiePisos
    .filter(item => item.value < 0).sort((a, b) => b.value - a.value);
 // Calcular el total de ingresos
 const totalIngresos = numero_pisos_generadores_dinero.reduce((total, item) => total + item.value, 0);

 // Calcular el total de gastos
 const totalGastos = numero_pisos_no_generadores_dinero.reduce((total, item) => total + item.value, 0);
// Función para calcular la suma de valores por ciudad y tipo de gestión
const calcularNumeroPisos = (pisos, ciudad, tipoGestion) => {
  let num_pisos = 0;
  return pisos.reduce((suma, piso) => {
    if (piso.ciudad === ciudad && piso.tipo_gestion === tipoGestion) {
      num_pisos = num_pisos +1;
      return num_pisos;
    }
    return num_pisos;
  }, 0);
};

const calcularValoresPromedio = (pisos, ciudad, tipoGestion) => {
  let num_pisos = 0;
  let total = pisos.reduce((suma, piso) => {
    if (piso.ciudad === ciudad && piso.tipo_gestion === tipoGestion) {
      num_pisos++;
      return suma + (piso.value || 0);
    }
    return suma;
  }, 0);
  if (num_pisos === 0) return 0;
  return (total / num_pisos).toFixed(2);
};


const calcularSumaValores = (pisos, ciudad, tipoGestion) => {
  return pisos.reduce((suma, piso) => {
    if (piso.ciudad === ciudad && piso.tipo_gestion === tipoGestion) {
      return suma + (piso.value || 0);
    }
    return suma;
  }, 0).toFixed(2);
};
const calcularPromedioReservas = (pisos, ciudad, tipoGestion) => {
  let num = 0;
  let total = pisos.reduce((suma, piso) => {
    
    if (piso.ciudad === ciudad && piso.tipo_gestion === tipoGestion) {
      num++;
      return suma + (piso.reservas || 0);
    }
    return suma;
  }, 0).toFixed(2);
  if (num === 0) return 0;
  return (total / num).toFixed(2);
};

const calcularReservasTotales = (pisos, ciudad, tipoGestion) => {
  return pisos.reduce((suma, piso) => {
    if (piso.ciudad === ciudad && piso.tipo_gestion === tipoGestion) {
      return suma + (piso.reservas || 0);
    }
    return suma;
  }, 0).toFixed(2);
};
const calcularReservasTotalesPorCiudad = (pisos, ciudad, tipoGestion) => {
  return pisos.reduce((suma, piso) => {
    if (piso.ciudad === ciudad ) {
      return suma + (piso.reservas || 0);
    }
    return suma;
  }, 0).toFixed(2);
};

// Lista de ciudades únicas
const ciudadesUnicas = [
  ...new Set([
    ...numero_pisos_generadores_dinero.map(piso => piso.ciudad),
    ...numero_pisos_no_generadores_dinero.map(piso => piso.ciudad)
  ])
];
// Lista de tipos de gestión únicos
const tiposGestionUnicos = [...new Set([
  ...numero_pisos_generadores_dinero.map(piso => piso.tipo_gestion),
  ...numero_pisos_no_generadores_dinero.map(piso => piso.tipo_gestion),

])];

const exportToExcel = (data, columns) => {
  const ws = XLSX.utils.json_to_sheet(data.map(row => {
    const formattedRow = {};
    columns.forEach(col => {
      formattedRow[col.title] = row[col.dataIndex];
    });
    return formattedRow;
  }));
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Datos por Piso');
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const dataBlob = new Blob([excelBuffer], { type: 'application/octet-stream' });
  saveAs(dataBlob, 'Datos_por_piso.xlsx');
};

const handleCopy = () => {
  // Crear un array de strings para los datos
  let copyText = datos_facturacion_por_pisoCol.map(col => col.title).join('\t') + '\n'; // Encabezados
  datos_facturacion_por_piso.forEach(record => {
    copyText += datos_facturacion_por_pisoCol.map(col => record[col.dataIndex]).join('\t') + '\n';
  });

  // Copiar al portapapeles
  navigator.clipboard.writeText(copyText)
    .then(() => {
      alert('Datos copiados al portapapeles');
    })
    .catch(err => {
      console.error('Error al copiar al portapapeles: ', err);
    });
};
const [expandedRowKey, setExpandedRowKey] = useState(null);

// Función para manejar el cambio de expansión
const handleExpand = (expanded, record) => {
  // Si se expande, establece el _id de la fila en el estado
  // Si se colapsa, establece el estado como null
  console.log("handleExpand", expanded, record)
  setExpandedRowKey(expanded ? record.nombre : null);
};
const [modalVisible, setModalVisible] = useState(false);

// Función para abrir y cerrar el modal
const toggleModal = () => {
  setModalVisible(!modalVisible);
};

  return (
    <>
      {/* <Button
        type="primary"
        onClick={() => setFactura_visible(!Factura_visible)}
      >
        {!Factura_visible ? "Mostrar Factura" : "Ocultar Factura"}{" "}
        <FilePdfOutlined />
      </Button> */}
      {Factura_visible ? (
        <Row gutter={24}>
          <Col span={20}>
            <Facturas
              datos_mes={datosAccum ?? []}
              inquilinos={usersActive ?? []}
              datos_facturacion={inmueble_facturacion ?? {}}
            />
          </Col>
        </Row>
      ) : (
        <></>
      )}
      {/* <LectorDni /> */}

      <div style={{ padding: "50px" }}>
        <Row gutter={24}>
          Buscar mes entero:
          <Col span={8}>
            <DatePicker onChange={onChange} picker="month" defaultValue={defaultValueMonth} />
          </Col>
          <Col span={8}>
            <Button
              onClick={() => onClickMeses()}
            >
              {" "}
              Buscar
            </Button>
          </Col>
        </Row>
        <Divider></Divider>
        <Row gutter={24}>
          Buscar por fechas:
          <Col>
            <RangePicker
              renderExtraFooter={() => "extra footer"}
              value={fechas_busqueda}
              onChange={(dates) => {
                //console.log("onChange", fechas_busqueda);
                setfechas_busqueda([
                  moment.utc(dates[0].format("YYYY-MM-DD")),
                  moment.utc(dates[1].format("YYYY-MM-DD")),
                ]);
              }}
            />
          </Col>
          <Col span={8}>
            <Button
              onClick={() => onClickPorFechas()}
            >
              {" "}
              Buscar
            </Button>
          </Col>
        </Row>
      </div>


     
      {/* <ReservasDuplicadas data={reservasDuplicadas??""} mes={monthQuery} year={yearQuery} ></ReservasDuplicadas> */}
      <Collapse>
     
     <Collapse.Panel header="Duplicadas">
<Duplicadas accesToken ={getAccessTokenApi()} monthQuery={monthQuery} yearQuery={yearQuery}></Duplicadas>
</Collapse.Panel>
</Collapse>
       <Collapse>
     
              <Collapse.Panel header="La Tabla">
                <Tabla_global monthQuery={monthQuery} yearQuery={yearQuery}></Tabla_global>
              
              </Collapse.Panel>
      
      
      <Collapse.Panel header="Datos globales">
      
      <div style={{ width: "100%", height: "100%" }}>
        <Row style={{ width: "100%", height: "100%" }} gutter={24}>
          {datosFacturacion.length > 0 ? (
            <TablePlot
              data={datosAccum}
              columns={columnasContabilidad}
              style={{ width: "100%", height: "100%" }}
            />
          ) : (
            <></>
          )}
        </Row>
      </div>
      
      
      {/* <Row style={{ width: "100%", height: "100%" }} gutter={24}>
        {metricasAccum !== [] ? (
          <TablePlot
            data={metricasAccum}
            columns={metricasAccumCol}
            style={{ width: "100%", height: "100%" }}
          />
        ) : (
          <></>
        )}
      </Row> */}
      {datosPie.length > 0 ? (
        <>
        
        <Row gutter={24}>
          <Col span={12}>
            <DemoPie
              data_real={datosPie.slice(1)}
              my_title={"Total: " + datosPie[0].value}
            />
          </Col>
          <Col span={12}>
            <DemoPie
              data_real={datosPiePorcentages.slice(1)}
              my_title={"Total gen: " + datosPiePorcentages[0].value + "%"}
            />
          </Col>
        </Row>

          <Divider>Datos ciudades</Divider>
              <div style={{ width: "100%", height: "100%" }}>
                <Row style={{ width: "100%", height: "100%" }} gutter={24}>
                <DesplegableMultiple
                        valores={ciudadesUnicas}
                        onChange={(values) => {
                          setdatosAccumPorCiudad(values)
                        }}
                      />
                  {datosFacturacion.length > 0 ? (
                    <TablePlot
                      data={filtrar_ciudad(usersActive, datosAccumPorCiudad)}
                      columns={columnasContabilidad}
                      style={{ width: "100%", height: "100%" }}
                    />
                  ) : (
                    <></>
                  )}
                </Row>
              </div>
              <table border="1">
              <tr>
              <th></th>
                            <th>Reservas</th>
                            <th>Días</th>
                            <th>Facturación</th>
                            <th>Facturación por Reserva</th>
                            <th>Días por Reserva</th>
                            <th>Facturación por Día</th>
                </tr>
                <tr>
                  <td>Generales</td>
                  <td>{filtrar_ciudad(usersActive, datosAccumPorCiudad)?.generales?.reservas}</td>
                  <td>{filtrar_ciudad(usersActive, datosAccumPorCiudad)?.generales?.dias}</td>
                  <td>{filtrar_ciudad(usersActive, datosAccumPorCiudad)?.generales?.Facturacion}</td>
                  <td>{(filtrar_ciudad(usersActive, datosAccumPorCiudad)?.generales?.Facturacion/filtrar_ciudad(usersActive, datosAccumPorCiudad)?.generales?.reservas)?.toFixed(2) }</td>
                  <td>{(filtrar_ciudad(usersActive, datosAccumPorCiudad)?.generales?.dias/filtrar_ciudad(usersActive, datosAccumPorCiudad)?.generales?.reservas)?.toFixed(2)}</td>
                  <td>{(filtrar_ciudad(usersActive, datosAccumPorCiudad)?.generales?.Facturacion/filtrar_ciudad(usersActive, datosAccumPorCiudad)?.generales?.dias)?.toFixed(2)}</td>
                </tr>
              </table>
              <Row gutter={24}>
              

            <Col span={12}>
              <DemoPie
                data_real={filtrar_ciudad(usersActive, datosAccumPorCiudad)?.operativas}
                my_title={"Total: " + filtrar_ciudad(usersActive, datosAccumPorCiudad)?.generales?.Facturacion}
              />
            </Col>
            <Col span={12}>
              <DemoPie
                data_real={filtrar_ciudad(usersActive, datosAccumPorCiudad)?.operativas_percentaje}
                my_title={"Total gen: 100" + "%"}
              />
            </Col>
          </Row>
  
      
      
      
    <Divider>Datos facturación por pisos</Divider>
    <Row gutter={24} style={{ textAlign: 'center', marginBottom: '20px' }}>


  <div style={{ margin: '0 auto', width: 'fit-content' }}>
  <Gastos buscar={false} visible={false}  fecha_inicio={moment().startOf('month')} fecha_fin={moment().endOf('month')}   />   {/* fecha_inicio={moment().startOf('month')} fecha_fin={moment().endOf('month')} */}
  {/* <Gastos visible={false}  /> */}

  <p style={{ fontSize: '18px', fontWeight: 'bold' }}>Total reservas: {numero_reservas} </p>
    <p style={{ fontSize: '18px', fontWeight: 'bold' }}>
      Numero de pisos generadores de dinero: {numero_pisos_generadores_dinero.length}
    </p>
    <p style={{ fontSize: '18px', fontWeight: 'bold' }}>
      Numero de pisos no dejan dinero: {numero_pisos_no_generadores_dinero.length}
    </p>
    <p style={{ fontSize: '18px', fontWeight: 'bold' }}>
  Media de valores de pisos generadores de dinero: {
    numero_pisos_generadores_dinero.length > 0 ? (
      (() => {
        // Sumar todos los valores de los pisos generadores de dinero
        const sumaGeneradores = numero_pisos_generadores_dinero.reduce((sum, item) => sum + item.value, 0);

        // Sumar todos los valores de los pisos no generadores de dinero
       // const sumaNoGeneradores = numero_pisos_no_generadores_dinero.reduce((sum, item) => sum + item.value, 0);

        // Calcular el total de pisos sumando la longitud de ambos arrays
        const totalPisos = numero_pisos_generadores_dinero.length ;

        // Calcular la media de los valores de todos los pisos
        const media = totalPisos > 0 ? (sumaGeneradores ) / totalPisos : 'No hay pisos';

        // Mostrar el resultado en el componente
        return (
          <>
          <p style={{ fontSize: '18px', fontWeight: 'bold' }}>
            Media de valores de los pisos generadores: {Number(media).toFixed(2)}
          </p>
           <p style={{ fontSize: '18px', fontWeight: 'bold' }}>
           Total generado: {Number(media * (numero_pisos_generadores_dinero.length )).toFixed(2)}
         </p>
         </>
        );
      })()
    ) : 'No hay pisos no generadores de dinero'
  }
</p>
    
    <p style={{ fontSize: '18px', fontWeight: 'bold' }}>
  Media de valores de pisos no generadores de dinero: {
    numero_pisos_generadores_dinero.length > 0 ? (
      (() => {
        // Sumar todos los valores de los pisos generadores de dinero
        const sumaGeneradores = numero_pisos_generadores_dinero.reduce((sum, item) => sum + item.value, 0);

        // Sumar todos los valores de los pisos no generadores de dinero
        const sumaNoGeneradores = numero_pisos_no_generadores_dinero.reduce((sum, item) => sum + item.value, 0);

        // Calcular el total de pisos sumando la longitud de ambos arrays
        const totalPisos = numero_pisos_generadores_dinero.length + numero_pisos_no_generadores_dinero.length;

        // Calcular la media de los valores de todos los pisos
        const media = totalPisos > 0 ? (sumaGeneradores + sumaNoGeneradores) / totalPisos : 'No hay pisos';

        // Mostrar el resultado en el componente
        return (
          <>
          <p style={{ fontSize: '18px', fontWeight: 'bold' }}>
            Media de valores de todos los pisos: {Number(media).toFixed(2)}
          </p>
           <p style={{ fontSize: '18px', fontWeight: 'bold' }}>
           Total generado: {Number(media * (numero_pisos_generadores_dinero.length + numero_pisos_no_generadores_dinero.length)).toFixed(2)}
         </p>
         </>
        );
      })()
    ) : 'No hay pisos no generadores de dinero'
  }
</p>


  </div>
 
  <table style={{ borderCollapse: 'collapse', width: '100%', margin: '0 auto' }}>
  <thead>
  <tr>
          <td colSpan="2" style={{ border: '1px solid black', textAlign: 'center', fontWeight: 'bold' }}>Ingresos:</td>
          <td style={{border: '1px solid black', textAlign: 'center', fontWeight: 'bold'  }}>{totalIngresos}</td>
        </tr>
        <tr>
          <td colSpan="2" style={{ border: '1px solid black', textAlign: 'center', fontWeight: 'bold', color: 'red' }}>Gastos:</td>
          <td style={{ border: '1px solid black', textAlign: 'center', color: 'red' }}>{totalGastos}</td>
        </tr>
        <tr>
          <td colSpan="2" style={{ border: '1px solid black', textAlign: 'center', fontWeight: 'bold'  }}>Beneficio:</td>
          <td style={{ border: '1px solid black', textAlign: 'center', fontWeight: 'bold'  }}>{totalIngresos+totalGastos}</td>
        </tr>
   
  </thead>
  </table>
<Divider></Divider>
<table style={{ borderCollapse: 'collapse', width: '100%', margin: '0 auto' }}>
  <thead>
 
    <tr>
      <th style={{ border: '1px solid black', textAlign: 'center' }}>Indice</th>
      <th style={{ border: '1px solid black', textAlign: 'center' }}>Nombre de la propiedad</th>
      <th style={{ border: '1px solid black', textAlign: 'center' }}>Valor</th>
      <th style={{ border: '1px solid black', textAlign: 'center' }}>Nº Reservas</th>
      <th style={{ border: '1px solid black', textAlign: 'center' }}>Tipo piso</th>
      <th style={{ border: '1px solid black', textAlign: 'center' }}>Ciudad</th>
    </tr>
  </thead>
  <tbody>
    {numero_pisos_generadores_dinero.length > 0 && (
      <>
        {console.log("numero_pisos_generadores_dinero", numero_pisos_generadores_dinero)}
        {/* Mostrar el total de ingresos */}
        
        {/* Mostrar los pisos generadores de dinero */}
        {numero_pisos_generadores_dinero.map((item, index) => (
          <tr key={index}>
            <td style={{ border: '1px solid black', textAlign: 'center' }}>{index + 1}</td>
            <td style={{ border: '1px solid black', textAlign: 'center' }}>{item.nombre ??" "}</td>
            <td style={{ border: '1px solid black', textAlign: 'center' }}>{item.value ?? " "}</td>
            <td style={{ border: '1px solid black', textAlign: 'center' }}>{item.reservas ?? " "}</td>
            <td style={{ border: '1px solid black', textAlign: 'center' }}>{item.tipo_gestion??" "}</td>
            
            <td style={{ border: '1px solid black', textAlign: 'center' }}>{item.ciudad??" "}</td>
          </tr>
        ))}
      </>
    )}
    {numero_pisos_no_generadores_dinero.length > 0 ? (
      <>
        {/* Mostrar el total de gastos */}
       
        {/* Mostrar los pisos no generadores de dinero */}
        {numero_pisos_no_generadores_dinero.map((item, index) => (
          <tr key={index}>


            
            <td style={{ border: '1px solid black', textAlign: 'center', color: 'red' }}>{index + 1}</td>
            <td style={{ border: '1px solid black', textAlign: 'center', color: 'red' }}>{item.nombre ??" "}</td>
            <td style={{ border: '1px solid black', textAlign: 'center', color: 'red' }}>{item.value ?? " "}</td>
            <td style={{ border: '1px solid black', textAlign: 'center', color: 'red' }}>{item.reservas ?? " "}</td>
            <td style={{ border: '1px solid black', textAlign: 'center', color: 'red' }}>{item.tipo_gestion??" "}</td>
            
            <td style={{ border: '1px solid black', textAlign: 'center', color: 'red' }}>{item.ciudad??" "}</td>
          </tr>
        ))}
      </>
    ) : null}
  </tbody>
</table>

</Row>

<Divider>Analisis Rentabilidad Tipo de piso</Divider>

<Row>
  <h2>Generadores: Resumen de Datos por Ciudad y Tipo de Gestión</h2>
  <table style={{ borderCollapse: 'collapse', width: '100%', margin: '0 auto' }}>
    <thead>
      <tr>
        <th style={{ border: '1px solid black', textAlign: 'center' }}>Ciudad</th>
        {tiposGestionUnicos.map((tipoGestion, index) => (
          <React.Fragment key={index}>
            <th colSpan="5" style={{ border: '1px solid black', textAlign: 'center' }}>{tipoGestion}</th>
          </React.Fragment>
        ))}
      </tr>
      <tr>
        <th style={{ border: '1px solid black', textAlign: 'center' }}></th>
        {tiposGestionUnicos.map((tipoGestion, index) => (
          <React.Fragment key={index}>
            <th style={{ border: '1px solid black', textAlign: 'center' }}>Suma Total</th>
            <th style={{ border: '1px solid black', textAlign: 'center' }}># Pisos</th>
            <th style={{ border: '1px solid black', textAlign: 'center' }}>Promedio por Piso</th>
            <th style={{ border: '1px solid black', textAlign: 'center' }}>Reservas totales</th>
            <th style={{ border: '1px solid black', textAlign: 'center' }}>Reservas promedio</th>
            
          </React.Fragment>
        ))}
      </tr>
    </thead>
    <tbody>
      {ciudadesUnicas.map((ciudad, index) => (
        <tr key={index}>
          <td style={{ border: '1px solid black', textAlign: 'center' }}>{ciudad}</td>
          {tiposGestionUnicos.map((tipoGestion, typeIndex) => (
            <React.Fragment key={typeIndex}>
              <td style={{ border: '1px solid black', textAlign: 'center' }}>
                {calcularSumaValores(numero_pisos_generadores_dinero, ciudad, tipoGestion)}
              </td>
              <td style={{ border: '1px solid black', textAlign: 'center' }}>
                {calcularNumeroPisos(numero_pisos_generadores_dinero, ciudad, tipoGestion)}
              </td>
              <td style={{ border: '1px solid black', textAlign: 'center' }}>
                {calcularValoresPromedio(numero_pisos_generadores_dinero, ciudad, tipoGestion)}
              </td>
              <td style={{ border: '1px solid black', textAlign: 'center' }}>
                {calcularReservasTotales(numero_pisos_generadores_dinero, ciudad, tipoGestion)}
              </td>
              <td style={{ border: '1px solid black', textAlign: 'center' }}>
                {calcularPromedioReservas(numero_pisos_generadores_dinero, ciudad, tipoGestion)}
              </td>
             
            </React.Fragment>
          ))}
        </tr>
      ))}
    </tbody>
  </table>
</Row>
<Row>
  <h2>No generadores: Resumen de Datos por Ciudad y Tipo de Gestión</h2>
  <table style={{ borderCollapse: 'collapse', width: '100%', margin: '0 auto' }}>
    <thead>
      <tr>
        <th style={{ border: '1px solid black', textAlign: 'center' }}>Ciudad</th>
        {tiposGestionUnicos.map((tipoGestion, index) => (
          <React.Fragment key={index}>
            <th colSpan="5" style={{ border: '1px solid black', textAlign: 'center' }}>{tipoGestion}</th>
          </React.Fragment>
        ))}
      </tr>
      <tr>
        <th style={{ border: '1px solid black', textAlign: 'center' }}></th>
        {tiposGestionUnicos.map((tipoGestion, index) => (
          <React.Fragment key={index}>
            <th style={{ border: '1px solid black', textAlign: 'center' }}>Suma Total</th>
            <th style={{ border: '1px solid black', textAlign: 'center' }}># Pisos</th>
            <th style={{ border: '1px solid black', textAlign: 'center' }}>Promedio por Piso</th>
            <th style={{ border: '1px solid black', textAlign: 'center' }}>Reservas totales</th>
            <th style={{ border: '1px solid black', textAlign: 'center' }}>Reservas promedio</th>
            {/* <th style={{ border: '1px solid black', textAlign: 'center' }}>Total reservas/ciudad</th> */}
          </React.Fragment>
        ))}
      </tr>
    </thead>
    <tbody>
      {ciudadesUnicas.map((ciudad, index) => (
        <tr key={index}>
          <td style={{ border: '1px solid black', textAlign: 'center' }}>{ciudad}</td>
          {tiposGestionUnicos.map((tipoGestion, typeIndex) => (
            <React.Fragment key={typeIndex}>
              <td style={{ border: '1px solid black', textAlign: 'center' }}>
                {calcularSumaValores(numero_pisos_no_generadores_dinero, ciudad, tipoGestion)}
              </td>
              <td style={{ border: '1px solid black', textAlign: 'center' }}>
                {calcularNumeroPisos(numero_pisos_no_generadores_dinero, ciudad, tipoGestion)}
              </td>
              <td style={{ border: '1px solid black', textAlign: 'center' }}>
                {calcularValoresPromedio(numero_pisos_no_generadores_dinero, ciudad, tipoGestion)}
              </td>
              <td style={{ border: '1px solid black', textAlign: 'center' }}>
                {calcularReservasTotales(numero_pisos_no_generadores_dinero, ciudad, tipoGestion)}
              </td>
              <td style={{ border: '1px solid black', textAlign: 'center' }}>
                {calcularPromedioReservas(numero_pisos_no_generadores_dinero, ciudad, tipoGestion)}
              </td>
              {/* <td style={{ border: '1px solid black', textAlign: 'center' }}>
                {calcularReservasTotalesPorCiudad(numero_pisos_no_generadores_dinero, ciudad, tipoGestion)}
              </td> */}
            </React.Fragment>
          ))}
        </tr>
      ))}
    </tbody>
  </table>
</Row>
<Divider>Visualmente</Divider>
    <Row gutter={24}>
    <Col span={12}>
      <DemoPie
        data_real={dataParaPieDirecto}
        my_title={tituloValorTotal}
      />
    </Col>
    <Col span={12}>
      <DemoPie
        data_real={dataParaPie}
        my_title={"Total gen: 100%"}
      />
    </Col>
  </Row>
        </>

      ) : (
        <></>
      )}
</Collapse.Panel>
    <Collapse.Panel header="Datos por piso">

      
     
      {/* <Row style={{ marginTop: 16 }}>
        <Button type="primary" onClick={() => exportToExcel(datos_facturacion_por_piso, datos_facturacion_por_pisoCol)}>
          Descargar en Excel
        </Button>
      </Row> */}
      <Row gutter={24}>
      {/* <Button onClick={handleCopy}>Copiar Tabla2</Button> */}
      {/* <Select
        onChange={(e) => {
                 
          setserie_id(e);
        }}
        placeholder={"Selecciona una serie"}>
        {series}
      </Select> */}
      <Select
        onChange={(e) => {
                 
          setciudad_remesa(e);
        }}
        style={{minWidth:"200px"}}
        mode="multiple"
        placeholder={"Selecciona una ciudad para la remesa"}>
        {ciudadesUnicas.map(x=> <option value={x}>{x}</option>)}
      </Select>
      <Divider></Divider>             
        <GenerarRemesaNorma34 datos_facturacion_por_piso={datos_facturacion_por_piso}  mes = {monthQuery}
                      year = {yearQuery} tipo_gestion={"Gestion_bruta"} ciudad ={ciudad_remesa}/>
      <Divider></Divider>
        <GenerarRemesaNorma34 datos_facturacion_por_piso={datos_facturacion_por_piso}  mes = {monthQuery}
                      year = {yearQuery} tipo_gestion={"Subarriendo"} ciudad ={ciudad_remesa}/>
      <Divider></Divider>

      <Button type="primary" onClick={toggleModal}>
        Abrir GENERADOR_Facturas
      </Button>
      <Modal
        title="Título del Modal"
        visible={modalVisible}
        onCancel={toggleModal} // Cierra el modal al hacer clic en "Cancelar"
        footer={[
          <Button key="cancel" onClick={toggleModal}>
            Cerrar
          </Button>,
        ]}
      >
       <ListaFacturasModal facturas={datos_facturacion_por_piso} monthQuery={monthQuery} yearQuery={yearQuery} />
       </Modal>
      <Divider></Divider>
          

      
  {/* Totales arriba */}
  <div style={{ padding: "10px", background: "#f5f5f5", marginBottom: "10px" }}>
  <table style={{ width: "100%", borderCollapse: "collapse", textAlign: "left" }}>
    <thead>
      <tr style={{ background: "#e8e8e8", fontWeight: "bold", borderBottom: "2px solid #ccc" }}>
        <th style={{ padding: "10px" }}>Concepto</th>
        <th style={{ padding: "10px" }}>Total</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td style={{ padding: "10px", borderBottom: "1px solid #ccc" }}>Total Facturación</td>
        <td style={{ padding: "10px", borderBottom: "1px solid #ccc" }}>
          {datos_facturacion_por_piso
            .reduce((sum, record) => sum + (record.Facturacion || 0), 0)
            .toLocaleString("es-ES", { style: "currency", currency: "EUR" })}
        </td>
      </tr>
      <tr>
  <td style={{ padding: "10px", borderBottom: "1px solid #ccc" }}><strong>Total Recibido en Banco </strong></td>
  <td style={{ padding: "10px", borderBottom: "1px solid #ccc" }}>
    <strong>{datos_facturacion_por_piso
      .reduce((sum, record) => {
        // Asegúrate de que cada valor sea un número válido, o usa 0 por defecto
        const totalAirbnb = Number(record.total_airbnb || 0);
        const totalBooking = Number(record.total_booking || 0);
        const totalReferido = Number(record.total_referido || 0);

        const comisionesAirbnb = Number(record.total_airbnb_comisiones || 0);
        const comisionesBooking = Number(record.total_booking_comisiones || 0);
        const comisionesReferido = Number(record.total_referido_comisiones || 0);

        // Calcula lo recibido en banco para este registro
        const recibidoBanco =
          totalAirbnb + totalBooking + totalReferido - (comisionesAirbnb + comisionesBooking + comisionesReferido);

        // Acumula el total general
        return sum + recibidoBanco;
      }, 0)
      .toLocaleString("es-ES", { style: "currency", currency: "EUR" })}</strong>
  </td>
</tr>

      <tr>
        <td style={{ padding: "10px", borderBottom: "1px solid #ccc" }}>Total Airbnb</td>
        <td style={{ padding: "10px", borderBottom: "1px solid #ccc" }}>
          {datos_facturacion_por_piso
            .reduce((sum, record) => sum + Number(record.total_airbnb || 0), 0)
            .toLocaleString("es-ES", { style: "currency", currency: "EUR" })}
        </td>
      </tr>
      <tr>
        <td style={{ padding: "10px", borderBottom: "1px solid #ccc" }}>Total Airbnb Comisiones</td>
        <td style={{ padding: "10px", borderBottom: "1px solid #ccc" }}>
          {datos_facturacion_por_piso
            .reduce((sum, record) => sum + Number(record.total_airbnb_comisiones || 0), 0)
            .toLocaleString("es-ES", { style: "currency", currency: "EUR" })}
        </td>
      </tr>
      <tr>
        <td style={{ padding: "10px", borderBottom: "1px solid #ccc" }}>Airbnb - Recibido en Banco</td>
        <td style={{ padding: "10px", borderBottom: "1px solid #ccc" }}>
          {datos_facturacion_por_piso
            .reduce(
              (sum, record) =>
                sum +
                Number(record.total_airbnb || 0) -
                Number(record.total_airbnb_comisiones || 0),
              0
            )
            .toLocaleString("es-ES", { style: "currency", currency: "EUR" })}
        </td>
      </tr>
      <tr>
        <td style={{ padding: "10px", borderBottom: "1px solid #ccc" }}>Total Booking</td>
        <td style={{ padding: "10px", borderBottom: "1px solid #ccc" }}>
          {datos_facturacion_por_piso
            .reduce((sum, record) => sum + Number(record.total_booking || 0), 0)
            .toLocaleString("es-ES", { style: "currency", currency: "EUR" })}
        </td>
      </tr>
      <tr>
        <td style={{ padding: "10px", borderBottom: "1px solid #ccc" }}>Total Booking Comisiones</td>
        <td style={{ padding: "10px", borderBottom: "1px solid #ccc" }}>
          {datos_facturacion_por_piso
            .reduce((sum, record) => sum + Number(record.total_booking_comisiones || 0), 0)
            .toLocaleString("es-ES", { style: "currency", currency: "EUR" })}
        </td>
      </tr>
      <tr>
        <td style={{ padding: "10px", borderBottom: "1px solid #ccc" }}>Booking - Recibido en Banco</td>
        <td style={{ padding: "10px", borderBottom: "1px solid #ccc" }}>
          {datos_facturacion_por_piso
            .reduce(
              (sum, record) =>
                sum +
                Number(record.total_booking || 0) -
                Number(record.total_booking_comisiones || 0),
              0
            )
            .toLocaleString("es-ES", { style: "currency", currency: "EUR" })}
        </td>
      </tr>
      <tr>
        <td style={{ padding: "10px", borderBottom: "1px solid #ccc" }}>Total Referido</td>
        <td style={{ padding: "10px", borderBottom: "1px solid #ccc" }}>
          {datos_facturacion_por_piso
            .reduce((sum, record) => sum + Number(record.total_referido || 0), 0)
            .toLocaleString("es-ES", { style: "currency", currency: "EUR" })}
        </td>
      </tr>
      <tr>
        <td style={{ padding: "10px", borderBottom: "1px solid #ccc" }}>Total Referido Comisiones</td>
        <td style={{ padding: "10px", borderBottom: "1px solid #ccc" }}>
          {datos_facturacion_por_piso
            .reduce((sum, record) => sum + Number(record.total_referido_comisiones || 0), 0)
            .toLocaleString("es-ES", { style: "currency", currency: "EUR" })}
        </td>
      </tr>
      <tr>
        <td style={{ padding: "10px", borderBottom: "1px solid #ccc" }}>Referido - Recibido en Banco</td>
        <td style={{ padding: "10px", borderBottom: "1px solid #ccc" }}>
          {datos_facturacion_por_piso
            .reduce(
              (sum, record) =>
                sum +
                Number(record.total_referido || 0) -
                Number(record.total_referido_comisiones || 0),
              0
            )
            .toLocaleString("es-ES", { style: "currency", currency: "EUR" })}
        </td>
      </tr>
      <tr>
        <td style={{ padding: "10px", borderBottom: "1px solid #ccc" }}>Total Días</td>
        <td style={{ padding: "10px", borderBottom: "1px solid #ccc" }}>
          {datos_facturacion_por_piso.reduce((sum, record) => sum + (record.dias || 0), 0)}
        </td>
      </tr>
    </tbody>
  </table>
</div>

    <Table
      dataSource={datos_facturacion_por_piso}
      
      columns={[
      
        {
          title: "Copiar datos",
          key: "copiar",
          render: (_, record) => (
            record.tipo_gestion == "Subarriendo" ? 
              record.generar_factura_propietario ?
                (<>
                {record.propietario_hago_tramite_iva_gestoria? "ENTREGAR IVA - SI": ""}
                <br></br>
                {record.irnr? "IRNR - SI": ""}
                <br></br>
                <Button
                style={{background:"yellow"}}
                onClick={ async() =>{
                  let response =  await getSeriesInmueble(getAccessTokenApi(),record._id);
                  let serie_a_enviar ="";
                  if(response.message){
                    serie_a_enviar = response.message.serie_propietario_a_empresa_gestora;
                  }
                  setserie_id(response.message.serie_propietario_a_empresa_gestora)
                const queryString = new URLSearchParams({
                  nombreEmisor: record.nombre_facturacion,
                  nifEmisor: record.id_facturacion,
                  direccionEmisor:record.direccion_facturacion,
                  cantidad:"1",
                  precioUd:Number((Number(record.Propietario_resto)/1.21)).toFixed(2),
                  descripcionItem: "Factura Alquiler " + record.nombre+ " Mes " + (monthQuery+1) + "/" + yearQuery,
                  nombreReceptor: "DPD RENTAL MANAGEMENT SL",
                  direccionReceptor: "CAMINO FUENTE DE LA JUNQUERA 142",
                  nifReceptor: "B72879711",
                  taxPercentage: ((record.irnr )|| false) ? "0%" : "21%" ,
                  taxNumber: record.irnr ? 0 : Number(Number(record.Propietario_resto) -Number(record.Propietario_resto)/1.21).toFixed(2),
                  inmueble:record._id,
                  serie_id: serie_a_enviar,
                  retencion:0.19,
                  irnr: record.irnr??false,
                  enviar_propietario: "si",
                  fecha_emision: moment().year(yearQuery).month(monthQuery).endOf('month').format('YYYY-MM-DD')
                 
                }).toString();
                // Redirigir usando window.location.href
                  // Construir la URL

                const url = `/admin/facturas?${queryString}`;

                // Abrir la URL en una nueva pestaña
                window.open(url, '_blank');

              } }
            >
               <b>{record.nombre}</b> -Fact. Subarriendo Propietario a mi 
                </Button>
                <Boton_factura_CC record={record} monthQuery={monthQuery} yearQuery={yearQuery} />
                <Button
                style={{background:"yellow"}}
                onClick={async() =>{
                let response =  await getSeriesInmueble(getAccessTokenApi(),record._id);
                let serie_a_enviar ="";
                if(response.message){
                  serie_a_enviar = response.message.serie_empresa_gestora_a_clientes_varios;
                }
                setserie_id(response.message.serie_empresa_gestora_a_clientes_varios)
                // if(serie_id === ""){
                //   notification["error"]({
                //     message: "Error selecciona una serie en el desplegable."
                //   });
                //   return;
                // }
                let a_facturar = Number(record.Facturacion) - Number(record.total_facturar_cc);
                const queryString = new URLSearchParams({
                  nombreEmisor: "DPD RENTAL MANAGEMENT SL",
                  nifEmisor: "B72879711" ,
                  direccionEmisor:"CAMINO FUENTE DE LA JUNQUERA 142",
                  cantidad:"1",
                  precioUd:Number((Number(a_facturar)/1.1)).toFixed(2),
                  descripcionItem: "Factura Alquiler a Clientes varios " + record.nombre+ " Mes " + (monthQuery+1) + "/" + yearQuery,
                  nombreReceptor: "CLIENTES VARIOS",
                  direccionReceptor: "",
                  nifReceptor: "",
                  taxPercentage: "10%",
                  taxNumber:Number(Number(a_facturar) - Number(a_facturar)/1.1).toFixed(2),
                  inmueble:record._id,
                  serie_id: serie_a_enviar,
                  retencion:0,
                  irnr: record.irnr??false,
                  fecha_emision: moment().year(yearQuery).month(monthQuery).endOf('month').format('YYYY-MM-DD')
                
                }).toString();
                // Redirigir usando window.location.href
                  // Construir la URL

                const url = `/admin/facturas?${queryString}`;

                // Abrir la URL en una nueva pestaña
                window.open(url, '_blank');

                } }
                >
               <b>{record.nombre}</b> - Fact. Subarriendo CV Total: {Number(Number(record.Facturacion))}€ - Ya Facturado: {Number(record.total_facturar_cc)}€ = {Number(Number(record.Facturacion) - Number(record.total_facturar_cc)).toFixed(2)}€
         
                </Button>

              

            
                </>
          
          
          
          ):<> 
          
          <Boton_factura_CC record={record} monthQuery={monthQuery} yearQuery={yearQuery} />
          <Button
          style={{background:"yellow"}}
          onClick={async() =>{
          let response =  await getSeriesInmueble(getAccessTokenApi(),record._id);
          let serie_a_enviar ="";
          if(response.message){
            serie_a_enviar = response.message.serie_empresa_gestora_a_clientes_varios;
          }
          setserie_id(response.message.serie_empresa_gestora_a_clientes_varios)
          // if(serie_id === ""){
          //   notification["error"]({
          //     message: "Error selecciona una serie en el desplegable."
          //   });
          //   return;
          // }
          let a_facturar = Number(record.Facturacion) - Number(record.total_facturar_cc);
          const queryString = new URLSearchParams({
            nombreEmisor: "DPD RENTAL MANAGEMENT SL",
            nifEmisor: "B72879711" ,
            direccionEmisor:"CAMINO FUENTE DE LA JUNQUERA 142",
            cantidad:"1",
            precioUd:Number((Number(a_facturar)/1.1)).toFixed(2),
            descripcionItem: "Factura Alquiler a Clientes varios " + record.nombre+ " Mes " + (monthQuery+1) + "/" + yearQuery,
            nombreReceptor: "CLIENTES VARIOS",
            direccionReceptor: "",
            nifReceptor: "",
            taxPercentage: "10%",
            taxNumber:Number(Number(a_facturar) - Number(a_facturar)/1.1).toFixed(2),
            inmueble:record._id,
            serie_id: serie_a_enviar,
            retencion:0,
            irnr:false,
            fecha_emision: moment().year(yearQuery).month(monthQuery).endOf('month').format('YYYY-MM-DD')
          
          }).toString();
          // Redirigir usando window.location.href
            // Construir la URL

          const url = `/admin/facturas?${queryString}`;

          // Abrir la URL en una nueva pestaña
          window.open(url, '_blank');

          } }
          >
           <b>{record.nombre}</b> - Fact. Subarriendo CV Total: {Number(Number(record.Facturacion))}€ - YaFacturado: {Number(record.total_facturar_cc)}€ = {Number(Number(record.Facturacion)) - Number(record.total_facturar_cc)}€
         
            </Button>

       

      </>
            :<>
            <Button
               style={{background:"pink"}}
               onClick={
                async() =>{
                let response =  await getSeriesInmueble(getAccessTokenApi(),record._id);
                let serie_a_enviar ="";
                if(response.message){
                  serie_a_enviar = response.message.serie_factura_mi_gestion_a_propietario;
                }
                setserie_id(response.message.serie_factura_mi_gestion_a_propietario)
                const queryString = new URLSearchParams({
                  nombreEmisor: "DPD RENTAL MANAGEMENT SL",
                  nifEmisor: "B72879711",
                  direccionEmisor:"CAMINO FUENTE DE LA JUNQUERA 142",
                  cantidad:"1",
                  precioUd:Number((Number(record.emitir_factura)/1.21)).toFixed(2),
                  descripcionItem: `Factura servicio Gestión ${record.nombre} Mes ${monthQuery + 1}/${yearQuery} 
                    -Limpieza: ${record.propietario_paga_limpieza ? 0 : record.pago_limpieza}€
                    -Gestion: ${Number(record.ComoTuCasa) + Number(record.IVA_ComoTuCasa)}€
                    -OTAs: ${record.Comision}€ 
                    -Arreglos: ${record.precio_venta_publico}€
                    -Materiales: ${record.coste_materiales_usados_reparacion}€`,
                  nombreReceptor: record.nombre_facturacion,
                  direccionReceptor: record.direccion_facturacion,
                  nifReceptor: record.id_facturacion,
                  taxPercentage: "21%",
                  taxNumber:Number(Number(record.emitir_factura) -Number(record.emitir_factura)/1.21).toFixed(2),
                  inmueble:record._id,
                  serie_id: serie_a_enviar,
                  retencion:0,
                  irnr:false,
                  enviar_propietario: "si",
                  fecha_emision: moment().year(yearQuery).month(monthQuery).endOf('month').format('YYYY-MM-DD')
                }).toString();
                // Redirigir usando window.location.href
                  // Construir la URL
                const url = `/admin/facturas?${queryString}`;

                // Abrir la URL en una nueva pestaña
                window.open(url, '_blank');
              } }
            >
             <b>{record.nombre}</b> - Factura Gestion
            </Button>
             {/* <Boton_factura_gestion_CC record={record} monthQuery={monthQuery} yearQuery={yearQuery} /> */}
             </>
          ),
        },
        ...datos_facturacion_por_pisoCol, // Otras columnas
      
       
      ]}
        style={{ width: "100%", height: "100%" }}
        rowKey="nombre" 
        onChange={handleChange}
        pagination={{
          defaultPageSize: 300,
          showSizeChanger: true,
          pageSizeOptions: ["10", "50", "100", "200", "300"],
        }}
        expandable={{
          // Aquí establecemos si la fila es expandible o no basándonos en el estado
          rowExpandable: (record) => true,
          // Aquí determinamos cuál fila está expandida basándonos en el estado
          expandedRowKeys: expandedRowKey ? [expandedRowKey] : [], // Si expandedRowKey es null, pasa un array vacío
       
          onExpand: handleExpand,
            expandedRowRender: (record) => <p style={{ margin: 0 }}>
              <CalculosGlobalesPiso 
                  irnr = {record.irnr}
                  coste_materiales_usados_reparacion ={record.coste_materiales_usados_reparacion}
                  precio_venta_publico={record.precio_venta_publico}
                  texto = {JSON.stringify(record)}
                  ingreso_propietario ={record.ingreso_propietario}
                  reservas = {record.reservas } 
                  direccion_facturacion = {record.direccion_facturacion } 
                  id_facturacion = {record.id_facturacion } 
                  nombre_facturacion = {record.nombre_facturacion } 
                  iban = {record.iban } 
                  ciudad = {record.ciudad } 
                  nombre = {record.nombre } 
                  tipo_gestion = {record.tipo_gestion } 
                  Facturacion = {record.Facturacion } 
                  pago_limpieza = {record.pago_limpieza } 
                  Comision = {record.Comision } 
                  dias = {record.dias } 
                  ComoTuCasa = {record.ComoTuCasa } 
                  Propietario_gestion = {record.Propietario_gestion } 
                  Propietario_resto = {record.Propietario_resto } 
                  IVA_ComoTuCasa = {record.IVA_ComoTuCasa } 
                  IVA_Reservas_Subarriendo ={record.IVA_Reservas_Subarriendo }
                  inmueble={record._id} 
                  mes = {monthQuery}
                  year = {yearQuery}
                  propietario_paga_limpieza={record.propietario_paga_limpieza}
                  
                  ></CalculosGlobalesPiso></p>, // Contenido expandido
            // rowExpandable: (record) => record.name !== 'Not Expandable', // Condición opcional para expandir
          }}
        


          
      />
      </Row>
      </Collapse.Panel>



        <CollapsePanel header="Calculadora rentabilidad">
          <Calculadora_rentabilidad></Calculadora_rentabilidad>
        </CollapsePanel>





      </Collapse>
      {/* <DemoPie
              data_real={datosPiePorcentages.slice(1)}
              my_title={"Total: " + datosPiePorcentages[0].value + "%"}
            /> */}
    </>
  );
}
